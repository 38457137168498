import 'react-multi-carousel/lib/styles.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  SearchActivityHomeWidget,
  SearchCarHomeWidget,
  SearchHotelHomeWidget,
  autoSuggestHomeWidget,
} from '@/lib/searchHomeWidget'
import AuthContext from 'store/auth-context'
import Carousel from 'react-multi-carousel'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme4/index.module.css'
import CustomLink from '@/components/Link'
import DesktopCalenderTheme4Css from '../../../../../common/components/widgets/flightSearchWidget/theme4/index.module.css'
import DesktopHotelStylesTheme4 from '../../../../../common/components/widgets/hotelSearchWidget/theme4/index.module.css'
import FlightSearchTheme4CSS from '../../../../../common/components/widgets/flightSearchWidget/theme4/index.module.css'
import FlightSearchWidget from '../../../../../common/components/widgets/flightSearchWidget'
import HotelSearchWidget from '../../../../../common/components/widgets/hotelSearchWidget'
import Image from 'next/image'
import b2bWidgetStyle from '../../../../../common/components/b2bwidgets/theme4/index.module.css'
import cf from '@/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import parse from 'html-react-parser'
import styles from './index.module.css'
import { getAirlines } from '../../../../../common/lib/getAirlines'
import { getSupplierList } from '../../../../../common/lib/getSupplierList'
import getFareType from '../../../../../common/lib/getFareType'
import { useRouter } from 'next/router'
import MultiSelectDropDown from '../../../multiSelect/multiSelectDropDown'
import MultiSuppilerDropDownoption from '../../../../../common/components/multiDropdown/theme4'
import FareTypeDropDownoption from '../../../../../common/components/CheckboxDropdown/theme4'
import styleDerptfare from '../../../../../common/components/CheckboxDropdown/theme4/index.module.css'
import styleDerpt from '../../../../../common/components/multiDropdown/theme4/index.module.css'
import Link from 'next/link'
import ModalPopupComponent from '../../../../../common/components/ModalPopupComponent/ModalPopupComponent'
import swPopupCss from '../../../../../common/components/widgets/commonCss/swPopupCss.module.css'
import SearchWidgetActivity from '../../../../../common/components/widgets/activityWidget/index'
import ActivityTheme4cssfile from '../../../../../common/components/widgets/activityWidget/theme4/index.module.css'
import SearchWidgetActivityNew from '../../../../../common/components/widgets/activityWidgetNew/index'
import ActivityTheme2cssfileNew from '../../../../../common/components/widgets/activityWidgetNew/theme4/index.module.css'
import DesktopCalenderThemeOneCss from '../../../../../common/components/widgets/flightSearchWidget/theme1/index.module.css'
import b2bWidgetStyleMobile from '../../../../../common/components/b2bwidgets/theme4/mobile/index.module.css'
import FlightSearchMobileThemeOneCSS from '../../../../../common/components/widgets/flightSearchWidget/theme4/index.module.css'
import styleMobile from '../../../../../common/components/multiDropdown/theme4/mobile.module.css'
import multiselectMobile from '../../../../../common/components/multiselectMobile/multiSelectDropDownMobile.module.css'
import modalCss from '../../../../../common/components/ModalPopupComponent/index.module.css'
import HolidayTheme4cssfile from '../../../../../common/components/widgets/activityWidgetNew/theme4/index.module.css'
import MobileCalendarcssfile from '../../../../../common/components/calendar/activityCalendar/theme4/mobilecalender.module.css'
import PopupCsstheme4 from '../../../../../common/components/ModalPopupComponent/theme4/index.module.css'
import HolidaySearchWidget from '../../../../../common/components/widgets/activityWidgetNew/index'
import CarSearchWidget from '@/commonComponents/widgets/carSearchWidget'
import carWidgetCss from '../../../../../common/components/widgets/carSearchWidget/theme5/widget.module.css'

const availableSearchTypes = [
  'flight_search',
  'hotel_search',
  'activity_search',
  'experience_search',
  'holiday_search',
  'car_search',
  'others_search',
]
const times = ['Before 6 AM', '6 AM - 12 PM', '12 PM - 6 PM', 'After 6 PM']
const rbdClass = [
  { label: 'All', value: '*', isChecked: false },
  { label: 'A', value: 'A', isChecked: false },
  { label: 'B', value: 'B', isChecked: false },
  { label: 'C', value: 'C', isChecked: false },
  { label: 'D', value: 'D', isChecked: false },
  { label: 'E', value: 'E', isChecked: false },
  { label: 'F', value: 'F', isChecked: false },
  { label: 'G', value: 'G', isChecked: false },
  { label: 'H', value: 'H', isChecked: false },
  { label: 'I', value: 'I', isChecked: false },
  { label: 'J', value: 'J', isChecked: false },
  { label: 'K', value: 'K', isChecked: false },
  { label: 'L', value: 'L', isChecked: false },
  { label: 'M', value: 'M', isChecked: false },
  { label: 'N', value: 'N', isChecked: false },
  { label: 'O', value: 'O', isChecked: false },
  { label: 'P', value: 'P', isChecked: false },
  { label: 'Q', value: 'Q', isChecked: false },
  { label: 'R', value: 'R', isChecked: false },
  { label: 'S', value: 'S', isChecked: false },
  { label: 'T', value: 'T', isChecked: false },
  { label: 'U', value: 'U', isChecked: false },
  { label: 'V', value: 'V', isChecked: false },
  { label: 'W', value: 'W', isChecked: false },
  { label: 'X', value: 'X', isChecked: false },
  { label: 'Y', value: 'Y', isChecked: false },
  { label: 'Z', value: 'Z', isChecked: false },
]

const DepartureTimeOptions = [
  { label: 'All', value: '', isChecked: true },
  { label: 'Before 6 AM', value: '00000559', isChecked: false },
  { label: '6 AM - 12 PM', value: '06001159', isChecked: false },
  { label: '12 PM - 6 PM', value: '12001759', isChecked: false },
  { label: 'After 6 PM', value: '18002359', isChecked: false },
]

export default function Theme4Flow(props) {
  const [initFlightData, setInitFlightData] = useState([])
  const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([])
  const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [expandActivityName, setExpandActivityName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [getActivityAutoSuggestList, setActivityAutoSuggestList] = useState([])
  const [initHotelData, setInitHotelData] = useState([])
  const [homeBodyDesktop, setHomeBodyDesktop] = useState([])
  const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [hotelDefultDays, setHotelDefultDays] = useState(0)
  const [userProfile, setUserProfile] = useState('')
  const [indexOfWidgetToshow, setIndexOfWidget] = useState(1)
  const [widClicked, setWidClicked] = useState(false)
  const [airlineList, setAirlineList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [selectedAirlines, setSelectedAirlines] = useState([])
  // const [selectedSupplier, setSelectedSupplier] = useState([])
  const [selectedDepartureTime, setSelectedDepartureTime] = useState([])
  const [selectedRbdClass, setSelectedRbdClass] = useState([])
  const [SelectedClassValue, setSelectedClassValue] = useState({ label: 'Economy', id: 1 })
  const [SerachWidget, setSerachWidget] = useState('flight')
  const ctx = useContext(AuthContext)
  const flightWidRef = useRef(null)
  const hotelWidRef = useRef(null)
  const [rbdOptions, setrbdOptions] = useState(rbdClass)
  const [rbdOptionsOne, setrbdOptionsOne] = useState(rbdClass)
  const [rbdOptionsTwo, setrbdOptionsTwo] = useState(rbdClass)
  const [SelectFareType, setSelectFareType] = useState([])
  const [DepartureTime, setDepartureTime] = useState(DepartureTimeOptions)
  const [DepartureTimeOne, setDepartureTimeOne] = useState(DepartureTimeOptions)
  const [DepartureTimeTwo, setDepartureTimeTwo] = useState(DepartureTimeOptions)
  const [isActive, setIsActive] = useState('flight')
  const [isPopup, setPopup] = useState(false)
  const [menuData, setMenuData] = useState('')
  const [disclaimerpopup, setDisclaimerPopup] = useState(false)
  const [getCarDepartureSuggestionList, setCarDepartureSuggestionList] = useState([])
  const [getCarStopsSuggestionList, setCarStopsSuggestionList] = useState([])
  const [getCarReturnSuggestionList, setCarReturnSuggestionList] = useState([])

  const box = useRef(null)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  useEffect(() => {
    getFareType().then((res) => {
      // console.log("getFareType", res);
      if (res && res.data && res.data.length > 0) {
        let fareType = res.data.map((ele, id) => {
          return {
            label: ele.pax_type,
            value: ele.pax_type,
            isChecked: false,
            code: ele.pax_code,
            //  displayValue: ele
          }
        })
        setSelectFareType(fareType)
      }
    })
    getSupplierList().then((res) => {
      if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
        let supplier = res.data.data.map((ele, id) => {
          return {
            label: ele.id,
            value: ele.id,
            isChecked: false,
            //  displayValue: ele
          }
        })
        supplier.unshift({ label: 'All', value: '*', isChecked: false, displayValue: 'All' })
        setSupplierList(supplier)
      }
    })
    getAirlines().then((res) => {
      if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
        let airline = res.data.data.map((ele, id) => {
          return {
            label: ele.airline_name,
            value: ele.airline_code,
            isChecked: false,
            displayValue: ele,
          }
        })
        airline.unshift({ label: 'All', value: '*', isChecked: false, displayValue: 'All' })

        // console.log("airline",airline)

        setAirlineList(airline)
      }
    })

    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props.site_id).then((res) => {
      let userdata = {
        user: {
          ...props?.sessionData,
          userType: props?.sessionData.type,
        },
        isLoggedIn: true,
        isLoading: false,
      }
      if (res.status === true) {
        setUserProfile(userdata?.user)
      } else {
        setUserProfile(userdata?.user)
      }
    })

    if (props?.home?.data?.data?.body?.desktop) {
      setHomeBodyDesktop(props.home.data.data.body.desktop)

      /**FIND FIRST AVILLABLE WIDGET**/
      let indexOfFirstWidghts = props.home.data.data.body.desktop?.findIndex((x) =>
        availableSearchTypes.includes(x.type)
      )
      setIndexOfWidget(indexOfFirstWidghts)

      let hotelIndex = props.home.data.data.body.desktop.findIndex(
        (items) => items.type === 'hotel_search'
      )
      if (hotelIndex > -1) {
        setHotelDefultDays(props.home.data.data.body.desktop[hotelIndex].default_days)
      }
    }
    if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)
    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let index = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'flight_search'
        )
        let hotelIndex = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'hotel_search'
        )
        if (index !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[index].airports) &&
            props.home.data.data.body.desktop[index].airports.length > 0
          ) {
            // let sortedDefaultAirports = props?.home?.data?.data?.body?.desktop &&
            let sortedDefaultAirports = []
            if (
              props?.home?.data?.data?.body?.desktop &&
              props?.home?.data?.data?.body?.desktop[index] &&
              props?.home?.data?.data?.body?.desktop[index].airports &&
              props?.home?.data?.data?.body?.desktop[index].airports.length
            ) {
              sortedDefaultAirports = props?.home?.data?.data?.body?.desktop[index].airports.sort(
                (a, b) => {
                  return a.ORDER - b.ORDER
                }
              )
            }
            setInitFlightData([...sortedDefaultAirports])
            setDepartureSuggestionList([...sortedDefaultAirports])
            setGoingToSuggestionList([...sortedDefaultAirports])
          }
        }
        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }
    // showMore()
    // <script>
    //    {showMore()}
    // </script>
    setDisclaimerPopup(localStorage.getItem('showdisclaimer') == 'true' ? true : false)
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const getAutoSuggestDataFunc = (value, section, type, event) => {
    switch (section) {
      case 'flight':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setDepartureSuggestionList([...initFlightData])
            } else {
              setGoingToSuggestionList([...initFlightData])
            }
          }
        } else {
          autoSuggestHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                if (!res.data.length && value.length < 2) {
                  setDepartureSuggestionList([...initFlightData])
                } else {
                  setDepartureSuggestionList([...res.data])
                }
              } else {
                if (!res.data.length && value.length < 2) {
                  setGoingToSuggestionList([...initFlightData])
                } else {
                  setGoingToSuggestionList([...res.data])
                }
              }
            }
          })
        }
        break
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                setHotelAutoSuggestList([...res.data.result.records])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      case 'activity':
        SearchActivityHomeWidget({ autoSuggest: value }).then((res) => {
          if (!cf.isValueEmpty(res) && !cf.isValueEmpty(res.data) && res.data.status === true) {
            if (
              !cf.isValueEmpty(res.data.searchBycity) &&
              res.data.searchBycity !== 'Not Found' &&
              res.data.searchBycity.status === 'success'
            ) {
              if (
                !cf.isValueEmpty(res.data.searchBycity.result) &&
                res.data.searchBycity.result.length > 0
              ) {
                getActivityAutoSuggestList.cityList = [...res.data.searchBycity.result]
                //     setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                setActivityAutoSuggestList([...res.data.searchBycity.result])
                if (expandActivityName === false) {
                  setExpandActivityName(true)
                }
              }
              if (
                !cf.isValueEmpty(res.data.searchBycountry) &&
                res.data.searchBycountry !== 'Not Found' &&
                res.data.searchBycountry.status === 'success'
              ) {
                if (
                  !cf.isValueEmpty(res.data.searchBycountry.result) &&
                  res.data.searchBycountry.result.length > 0
                ) {
                  getActivityAutoSuggestList.countryList = [...res.data.searchBycountry.result]
                  //      setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                  setActivityAutoSuggestList([
                    ...getActivityAutoSuggestList.cityList,
                    ...getActivityAutoSuggestList.countryList,
                  ])

                  if (expandActivityName === false) {
                    setExpandActivityName(true)
                  }
                }
              }
            }
          }
        })
        break
      case 'car':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setCarDepartureSuggestionList([])
            } else if (type === 'stops') {
              setCarStopsSuggestionList([])
            } else {
              setCarReturnSuggestionList([])
            }
          }
        } else {
          SearchCarHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && res?.data?.result?.records?.length > 0 && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                if (res?.data?.result?.records?.length > 0) {
                //   setCarDepartureSuggestionList([...initFlightData])
                // } else {
                  setCarDepartureSuggestionList([...res.data.result.records])
                }
              } else if(type === 'stops') {
                setCarStopsSuggestionList([...res.data.result.records])
              } else {
                if (res?.data?.result?.records?.length > 0) {
                //   setCarReturnSuggestionList([...initFlightData])
                // } else {
                  setCarReturnSuggestionList([...res.data.result.records])
                }
              }
            }
          })
        }
        break
      default:
        break
    }
  }
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
    if (flightWidRef.current && !flightWidRef.current.contains(event.target)) {
      setWidClicked(false)
    }
    if (hotelWidRef.current && !hotelWidRef.current.contains(event.target)) {
      setWidClicked(false)
    }
  }

  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }

  const onClickWidgets = () => {
    setWidClicked(true)
  }

  const multiSelectDropdown = () => {
    airlineList.forEach((option) => {
      option.displayValue = option.airline_name
    })
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select Airline'}
        dropdownInputPlaceHolder={'Type here Airline'}
        options={airlineList}
        setoptions={setAirlineList}
      />
    )
  }

  const multiSelectSupplierDropdown = () => {
    supplierList.forEach((option) => {
      option.displayValue = option.name
    })

    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select Supplier'}
        dropdownInputPlaceHolder={'Type here Supplier'}
        options={supplierList}
        setoptions={setSupplierList}
        isMobile={props?.isMobile}
      />
    )
  }

  const selectFareTypeDropdown = () => {
    return (
      <FareTypeDropDownoption
        options={SelectFareType}
        setoptions={setSelectFareType}
        inputPlaceHolder={'Select Fare Type'}
        style={styleDerptfare}
        box={box}
      />
    )
  }
  const multiSelectDepartureTimeDropdown = () => {
    return (
      <MultiSuppilerDropDownoption
        options={DepartureTime}
        setoptions={setDepartureTime}
        inputPlaceHolder={'Departure Time'}
        style={styleDerpt}
        box={box}
      />
    )
  }

  const multiSelectDepartureTimeDropdownOne = () => {
    return (
      <MultiSuppilerDropDownoption
        options={DepartureTimeOne}
        setoptions={setDepartureTimeOne}
        inputPlaceHolder={'Departure Time'}
        style={styleDerpt}
        box={box}
      />
    )
  }

  const multiSelectDepartureTimeDropdownTwo = () => {
    return (
      <MultiSuppilerDropDownoption
        options={DepartureTimeTwo}
        setoptions={setDepartureTimeTwo}
        inputPlaceHolder={'Departure Time'}
        style={styleDerpt}
        box={box}
      />
    )
  }

  const multiSelectRBDClassDropdown = () => {
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select RBD Class'}
        dropdownInputPlaceHolder={'Type here RBD'}
        options={rbdOptions}
        setoptions={setrbdOptions}
        // SelectedClassValue={SelectedClassValue}
        setSelectedClassValue={setSelectedClassValue}
      />
    )
  }

  const multiSelectRBDClassDropdownOne = () => {
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select RBD Class'}
        dropdownInputPlaceHolder={'Type here RBD'}
        options={rbdOptionsOne}
        setoptions={setrbdOptionsOne}
      />
    )
  }

  const multiSelectRBDClassDropdownTwo = () => {
    return (
      <MultiSelectDropDown
        inputPlaceHolder={'Select RBD Class'}
        dropdownInputPlaceHolder={'Type here RBD'}
        options={rbdOptionsTwo}
        setoptions={setrbdOptionsTwo}
      />
    )
  }
  const multiDepartureTimeDropDown = () => {
    ;<MultiSuppilerDropDownoption
      options={DepartureTime}
      setoptions={setDepartureTime}
      inputPlaceHolder={'Departure Time'}
      style={styleDerpt}
      box={box}
    />
  }
  const onChangeClassValue = (element) => {
    setSelectedClassValue({
      label: element.label,
      id: element.id,
    })
    setSelectedRbdClass([])
  }

  const onClickHeaderMenu = (data, i) => {
    if (data.code == 'others') {
      window.location.href = data.url

      return
    }
    setMenuData(data.code)
    setPopup(true)
  }

  const renderInfo = (menudata) => {
    // menudata = menudata == 'holiday' ? 'holidays' : menudata
    switch (menudata) {
      case 'flight':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              {/* {this.renderLoginPopup(this.state.popupType)} */}
              <FlightSearchWidget
                // pageTheme={props?.home?.data?.site?.site_theme}
                pageTheme="theme4"
                isSRP={false}
                isOffline={false}
                homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                styles={FlightSearchMobileThemeOneCSS}
                b2bStyles={b2bWidgetStyle}
                calendarStyles={DesktopCalenderTheme4Css}
                getAutoSuggestData={(value, type, event) =>
                  getAutoSuggestDataFunc(value, 'flight', type, event)
                }
                getGoingToSuggestionList={getGoingToSuggestionList}
                getDepartureSuggestionList={getDepartureSuggestionList}
                dateFormat={props?.home?.data?.site?.date_format}
                userProfile={userProfile}
                multiSelectDropdown={multiSelectDropdown}
                multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                selectFareTypeDropdown={selectFareTypeDropdown}
                multiSelectDepartureTimeDropdown={multiSelectDepartureTimeDropdown}
                multiSelectRBDClassDropdown={multiSelectRBDClassDropdown}
                multiSelectRBDClassDropdownTwo={multiSelectRBDClassDropdownTwo}
                multiSelectRBDClassDropdownOne={multiSelectRBDClassDropdownOne}
                selectedAirlines={airlineList}
                selectedSupplier={supplierList}
                selectedDepartureTime={selectedDepartureTime}
                selectedRbdClass={rbdOptions}
                selectedRbdClassOne={rbdOptionsOne}
                selectedRbdClassTwo={rbdOptionsTwo}
                SelectedClassValue={SelectedClassValue}
                DepartureTime={DepartureTime}
                FareType={SelectFareType}
                multiDepartureTimeDropDown={multiDepartureTimeDropDown}
                multiSelectDepartureTimeDropdownOne={multiSelectDepartureTimeDropdownOne}
                multiSelectDepartureTimeDropdownTwo={multiSelectDepartureTimeDropdownTwo}
                setrbdOptions={setrbdOptions}
                setrbdOptionsOne={setrbdOptionsOne}
                setrbdOptionsTwo={setrbdOptionsTwo}
                DepartureTimeOne={DepartureTimeOne}
                DepartureTimeTwo={DepartureTimeTwo}
                isMobile={true}
                styles1={b2bWidgetStyleMobile}
                styleMobile={styleMobile}
                multiselectMobile={multiselectMobile}
                onClosePopup={() => setPopup(false)}
                modalCss={modalCss}
                siteData={props?.home?.data?.site}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'hotel':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              <HotelSearchWidget
                // pageTheme={props?.home?.data?.site?.site_theme}
                pageTheme="theme4"
                isSRP={false}
                isOffline={false}
                styles={DesktopHotelStylesTheme4}
                b2bStyles={b2bWidgetStyle}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                expandHotelNameBoolean={expandHotelNameBoolean}
                getDefaultDays={hotelDefultDays}
                getAutoSuggestList={getHotelAutoSuggestList}
                expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                dateFormat={props?.home?.data?.site?.date_format}
                userProfile={userProfile}
                Countrystyles={Countrystyles}
                isMobile={true}
                onClosePopup={() => setPopup(false)}
                modalCss={modalCss}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'holiday':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              <HolidaySearchWidget
                {...props}
                //  cssfile={cssfile}
                cssfile={HolidayTheme4cssfile}
                pageTheme={props?.home?.data?.site?.site_theme}
                calendarStyles={DesktopCalenderThemeOneCss}
                dateFormat={props?.home?.data?.site?.date_format}
                b2bStyles={b2bWidgetStyle}
                onClosePopup={() => setPopup(false)}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'activity':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              <SearchWidgetActivity
                {...props}
                //  cssfile={cssfile}
                cssfile={ActivityTheme4cssfile}
                pageTheme={props?.home?.data?.site?.site_theme}
                calendarStyles={MobileCalendarcssfile}
                dateFormat={props?.home?.data?.site?.date_format}
                b2bStyles={b2bWidgetStyle}
                isMobile={props?.isMobile}
                popupcss={PopupCsstheme4}
                isSrp={false}
                userProfile={userProfile}
                Countrystyles={Countrystyles}
                isOffline={false}
                onClosePopup={() => setPopup(false)}
              />
            </ModalPopupComponent>
          </div>
        )
        case 'car':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              {/* {this.renderLoginPopup(this.state.popupType)} */}
              <CarSearchWidget
                pageTheme="theme4"
                isSRP={false}
                isOffline={false}
                homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                styles={carWidgetCss}
                b2bStyles={b2bWidgetStyle}
                calendarStyles={DesktopCalenderTheme4Css}
                getAutoSuggestData={(value, type, event) =>
                  getAutoSuggestDataFunc(value, 'car', type, event)
                }
                getGoingToSuggestionList={getCarReturnSuggestionList}
                getDepartureSuggestionList={getCarDepartureSuggestionList}
                getCarStopsSuggestionList={getCarStopsSuggestionList}
                dateFormat={props?.home?.data?.site?.date_format}
                userProfile={userProfile}
                multiSelectDropdown={multiSelectDropdown}
                multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                selectFareTypeDropdown={selectFareTypeDropdown}
                multiSelectDepartureTimeDropdown={multiSelectDepartureTimeDropdown}
                multiSelectRBDClassDropdown={multiSelectRBDClassDropdown}
                multiSelectRBDClassDropdownTwo={multiSelectRBDClassDropdownTwo}
                multiSelectRBDClassDropdownOne={multiSelectRBDClassDropdownOne}
                selectedAirlines={airlineList}
                selectedSupplier={supplierList}
                selectedDepartureTime={selectedDepartureTime}
                selectedRbdClass={rbdOptions}
                selectedRbdClassOne={rbdOptionsOne}
                selectedRbdClassTwo={rbdOptionsTwo}
                SelectedClassValue={SelectedClassValue}
                DepartureTime={DepartureTime}
                FareType={SelectFareType}
                multiDepartureTimeDropDown={multiDepartureTimeDropDown}
                multiSelectDepartureTimeDropdownOne={
                  multiSelectDepartureTimeDropdownOne
                }
                multiSelectDepartureTimeDropdownTwo={
                  multiSelectDepartureTimeDropdownTwo
                }
                setSelectFareType={setSelectFareType}
                isMobile={props?.isMobile}
                onClosePopup={() => {
                  setPopup(false)
                }}
              />
            </ModalPopupComponent>
          </div>
        )
    }
  }

  const router = useRouter()
  const query = router.query.index

  // useEffect(() => {
  //   if (window.location.pathname == '/') {
  //     setSerachWidget('flight')
  //   }
  //   if (window.location.pathname == '/hotels') {
  //     setSerachWidget('hotel')
  //   }
  //   if (window.location.pathname == '/activities') {
  //     setSerachWidget('activity')
  //   }
  //   if (window.location.pathname == '/experiences') {
  //     setSerachWidget('experience')
  //   }
  // }, [query])

  useEffect(() => {
    try {
      if (props?.home?.data?.data?.body?.desktop) {
        setHomeBodyDesktop(props.home.data.data.body.desktop)
      }

      if (props?.home?.data?.data?.body?.mobile) {
        setHomeBodyMobile(props.home.data.data.body.mobile)
      }
      if (
        window.location.pathname == '/' ||
        window.location.pathname == '/flight' ||
        window.location.pathname == '/flights'
      ) {
        setSerachWidget('flight')
        setIsActive('flight')
        if(props.isMobile && window.location.pathname !== '/' ) {
          setMenuData('flight')
          setPopup(true)
        }
      }
      if (window.location.pathname == '/hotel' || window.location.pathname == '/hotels') {
        setSerachWidget('hotel')
        setIsActive('hotel')
        if(props.isMobile) {
          setMenuData('hotel')
          setPopup(true)
        }
      }
      if (window.location.pathname == '/holidays' || window.location.pathname == '/holiday') {
        setSerachWidget('holiday')
        setIsActive('holidays')
        if(props.isMobile) {
          setMenuData('holiday')
          setPopup(true)
        }
      }

      if (window.location.pathname == '/car' || window.location.pathname == '/cars') {
        setSerachWidget('car')
        setIsActive('car')
      }

      if (window.location.pathname == '/activity' || window.location.pathname == '/activities') {
        setSerachWidget('activity')
        setIsActive('activity')
        if(props.isMobile) {
          setMenuData('activity')
          setPopup(true)
        }
      }

      if (window.location.pathname == '/experiences') {
        setSerachWidget('experience')
      }

      // setTimeout(() => {
      //   myGreetingMySwiper(),
      //     myGreetingMySwiper2(),
      //     myGreetingElevatenew(),
      //     myGreetingCruiseHolidays(),
      //     myGreetingChild_featureSwiper(),
      //     myGreetingTestimonial_data(),
      //     myGreetingMyclientSwiper(),
      //     myGreetingOffermain(),
      //     myGreetingNewlyadded(),
      //     myGreetingMostbooked(),
      //     myGreetingHoneymoonpkg(),
      //     myGreetingExploremain(),
      //     myGreetingBudgetSwiper()
      // }, 100)
    } catch (error) {
      console.log(error)
    }

    // console.log("query==>",props?.home?.data?.data);
  }, [query])
  const closeDisclaimPopup = () => {
    setDisclaimerPopup(false)
    localStorage.removeItem('showdisclaimer')
  }

  const showdisclaimerpopup = () => {
    return (
      <div className="renderinfo-flight">
        {disclaimerpopup ? (
          <ModalPopupComponent
            isMobile={true}
            isPopup={true}
            onClosePopup={() => {
              closeDisclaimPopup()
            }}
            modalCss={modalCss}
          >
            <div className={styles.enquiy_container}>
              <span className={styles.quote_success}>Please check your Personal Details</span>
              <div className={`${styles.btn_container} ${styles.btn_container_space}`}>
                <button
                  className={styles.ok_button}
                  onClick={() => {
                    closeDisclaimPopup()
                    cf.redirectTo('/myprofile')
                  }}
                >
                  View Profile
                </button>
                <button
                  className={styles.ok_button}
                  onClick={() => {
                    closeDisclaimPopup()
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </ModalPopupComponent>
        ) : null}
      </div>
    )
  }

  return (
    <>
      <>
        {/* carousal slider  starts */}

        {/* <div className={styles.img_banner_home_thtwo}>
                <img  className={styles.styleBanner} src='/theme2/logn_bg.png' />
           </div> */}
        <div
          className={`${styles.home_container} ${
            menuData && isPopup ? styles.mobile_home_container : ''
          }`}
        >
          {!props.isMobile && (
            <div>
              {homeBodyDesktop.map((res, i) => {
                return (
                  <div key={'homeBodyDsktp' + i}>
                    {!cf.isValueEmpty(res.type) &&
                      res.type == 'image_carousal' &&
                      (res.banners && res.banners.length > 0 ? (
                        <div className={styles.theme4_ban}>
                          <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={false} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={props.isMobile ? true : true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container_t4"
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                          >
                            {!cf.isValueEmpty(res) &&
                              !cf.isValueEmpty(res.banners) &&
                              res.banners.length > 0 &&
                              res.banners.map((imagedata, index) => {
                                return (
                                  // <div>index====={index}</div>
                                  // <img style={{ height: 300, background: '#EEE' }} src={`${ '/shared/api/media/'+props.site_id+'/' + imagedata.image}`} />
                                  <div key={'banner' + index} className="image_banner_home_4">
                                    <CustomLink href={imagedata.banner_url}>
                                      <Image
                                        loader={imageLoader}
                                        src={imagedata?.image}
                                        width={500}
                                        layout="fill"
                                      />
                                    </CustomLink>
                                    {/* <img src={`${'/shared/api/media/' + props.site_id + '/' + imagedata.image}`} /> */}
                                  </div>
                                  // <div className="testimonial_carousel">
                                  //    <div className='container mx-auto xl:px-8 flex items-center justify-center'>
                                  //       <div className="testimonial_left">
                                  //          <img src={`${ '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} />
                                  //       </div>
                                  //       <div className="testimonial_right">
                                  //          <div className="testimonial_header">Test</div>
                                  //          <div className="testimonial_text">
                                  //             Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                  //             Minima reiciendis, aliquid ipsam doloribus dignissimos
                                  //             ipsa. Quidem, architecto laudantium! Aut quas dolor nam
                                  //             eaque ullam vero voluptatibus non, molestiae quae beatae.
                                  //          </div>
                                  //          <div className='testimonial_name'>Test User</div>
                                  //          <div class="testimonial_star">
                                  //             <img src="https://uat.elevatetrips.com/image/star-rating-4.0.svg" alt="" />
                                  //          </div>
                                  //       </div>
                                  //    </div>
                                  // </div>
                                )
                              })}
                          </Carousel>
                        </div>
                      ) : (
                        <div className={styles.nobanner}></div>
                      ))}
                  </div>
                )
              })}
            </div>
          )}
          {/* carousal slider end */}
          {/* tab navbar starts */}
          <div className={styles.widget_container}>
            {!props.isMobile && (
              <div className="container mx-auto offiletab xl:px-8">
                <div className={styles.header_hero_cont}>
                  {/* <h2>Let's The World Together!</h2>
            <p>Find awesome hotel, flight in Dubai</p> */}
                </div>
                <div className={styles.search}>
                  <div className={styles.holidaymaintabnavbarrow}>
                    <div className={styles.holidaycarouselcontainer}>
                      <nav className={styles.navbartab}>
                        <ul>
                          {props?.home?.data?.data?.header?.menu?.map((res, i) => {
                            //console.log('ressssss', res)
                            return (
                              <>
                                {res.code.includes('flight') && (
                                  <Link href={res.url}>
                                    <span
                                      className={styles.serv_flx}
                                      onClick={() => {
                                        // window.open(res.url, '_self')
                                        setSerachWidget('flight')
                                      }}
                                    >
                                      <li
                                        className={
                                          SerachWidget === 'flight'
                                            ? `${styles.activetab}`
                                            : `${styles.inactive}`
                                        }
                                      >
                                        <div className={styles.service_icon}>
                                          {/* <img
                          src="/theme4/flightHome.svg"
                          alt=""
                        /> */}
                                          {res.icon == '' ? (
                                            <img src="images/forexImg.svg" alt="others" />
                                          ) : (
                                            <img
                                              src={`${
                                                '/shared/api/media/' +
                                                props.site_id +
                                                '/' +
                                                res.icon
                                              }`}
                                              alt="hotel"
                                            />
                                          )}
                                        </div>
                                      </li>
                                      <span className={styles.service_title}>{res.text}</span>
                                    </span>
                                  </Link>
                                )}

                                {res.code.includes('hotel') && (
                                  <Link href={res.url}>
                                    <span
                                      className={styles.serv_flx}
                                      onClick={() => {
                                        // window.open(res.url, '_self')
                                        setSerachWidget('hotel')
                                      }}
                                    >
                                      <li
                                        className={
                                          SerachWidget === 'hotel'
                                            ? `${styles.activetab}`
                                            : `${styles.inactive}`
                                        }
                                      >
                                        <div className={styles.service_icon}>
                                          {/* <img
                          src="/theme4/hotelHome.svg"
                          alt=""
                        /> */}
                                          {res.icon == '' ? (
                                            <img src="images/forexImg.svg" alt="others" />
                                          ) : (
                                            <img
                                              src={`${
                                                '/shared/api/media/' +
                                                props.site_id +
                                                '/' +
                                                res.icon
                                              }`}
                                              alt="hotel"
                                            />
                                          )}
                                        </div>
                                        {/* {res.text} */}
                                      </li>
                                      <span className={styles.service_title}>{res.text}</span>
                                    </span>
                                  </Link>
                                )}
                                {res.code.includes('activity') && (
                                  <Link href={res.url}>
                                    <span
                                      className={styles.serv_flx}
                                      onClick={() => {
                                        // window.open(res.url, '_self')
                                        setSerachWidget('activity')
                                      }}
                                    >
                                      <li
                                        className={
                                          SerachWidget === 'activity'
                                            ? `${styles.activetab}`
                                            : `${styles.inactive}`
                                        }
                                      >
                                        <div className={styles.service_icon}>
                                          {/* <img
                          src="/theme4/hotelHome.svg"
                          alt=""
                        /> */}
                                          {res.icon == '' ? (
                                            <img src="images/forexImg.svg" alt="others" />
                                          ) : (
                                            <img
                                              src={`${
                                                '/shared/api/media/' +
                                                props.site_id +
                                                '/' +
                                                res.icon
                                              }`}
                                              alt="activity"
                                            />
                                          )}
                                        </div>
                                        {/* {res.text} */}
                                      </li>
                                      <span className={styles.service_title}>{res.text}</span>
                                    </span>
                                  </Link>
                                )}

                                {res.code.includes('holiday') && (
                                  <Link href={res.url}>
                                    <span
                                      className={styles.serv_flx}
                                      onClick={() => {
                                        setSerachWidget('holiday')
                                        // window.open(res.url, '_self')
                                      }}
                                    >
                                      <li
                                        className={
                                          SerachWidget == 'holiday'
                                            ? `${styles.activetab}`
                                            : `${styles.inactive}`
                                        }
                                      >
                                        <div className={styles.service_icon}>
                                          {res.icon == '' ? (
                                            <img src="images/forexImg.svg" alt="others" />
                                          ) : (
                                            <img
                                              src={`${
                                                '/shared/api/media/' +
                                                props.site_id +
                                                '/' +
                                                res.icon
                                              }`}
                                              alt="holiday"
                                            />
                                          )}
                                        </div>
                                      </li>
                                      <span className={styles.service_title}>{res.text}</span>
                                    </span>
                                  </Link>
                                )}

                                {res.code.includes('car') && (
                                  <Link href={res.url}>
                                    <span
                                      className={styles.serv_flx}
                                      onClick={() => {
                                        setSerachWidget('car')
                                        // window.open(res.url, '_self')
                                      }}
                                    >
                                      <li
                                        className={
                                          SerachWidget == 'car'
                                            ? `${styles.activetab}`
                                            : `${styles.inactive}`
                                        }
                                      >
                                        <div className={styles.service_icon}>
                                          {res.icon == '' ? (
                                            <img src="images/forexImg.svg" alt="others" />
                                          ) : (
                                            <img
                                              src={`${
                                                '/shared/api/media/' +
                                                props.site_id +
                                                '/' +
                                                res.icon
                                              }`}
                                              alt="car"
                                            />
                                          )}
                                        </div>
                                      </li>
                                      <span className={styles.service_title}>{res.text}</span>
                                    </span>
                                  </Link>
                                )}

                                {res.code.includes('other') && (
                                  <Link href={res.url}>
                                    <li
                                      className={
                                        SerachWidget == 'others_search'
                                          ? `${styles.activetab}`
                                          : `${styles.inactive}`
                                      }
                                      // onClick={() => {
                                      //   setSerachWidget('others_search')
                                      //   window.open(res.url, '_self')
                                      // }}
                                    >
                                      <div className={styles.service_icon}>
                                        {/* <img
                            src="theme4/offlineIcon.svg"
                            alt=""
                          /> */}
                                        {res.icon == '' ? (
                                          <img src="images/forexImg.svg" alt="others" />
                                        ) : (
                                          <img
                                            src={`${
                                              '/shared/api/media/' + props.site_id + '/' + res.icon
                                            }`}
                                            alt="nav icon"
                                          />
                                        )}
                                      </div>
                                      <span className={styles.service_title}>{res.text}</span>
                                    </li>
                                  </Link>
                                )}
                              </>
                            )
                          })}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* tab navbar end */}
            {/* widgets components starts */}
            {disclaimerpopup && showdisclaimerpopup()}
            {!props.isMobile ? (
              <div>
                {homeBodyDesktop.map((res, i) => {
                  return (
                    <div key={'homeBodyDsktp' + i}>
                      {/* {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                                 <div className="img_banner_home">
                                   <Image loader={imageLoader} src={res.banners} layout="fill" width={500} />
                                 </div>
                               )} */}
                      {SerachWidget === 'flight' &&
                        !cf.isValueEmpty(res.type) &&
                        res.type == 'flight_search' && (
                          <div
                            onClick={onClickWidgets}
                            className={widClicked ? styles.parentWid : ''}
                          >
                            <div
                              ref={flightWidRef}
                              onClick={onClickWidgets}
                              className={widClicked ? styles.childWid : ''}
                            >
                              {!props.isMobile && (
                                <FlightSearchWidget
                                  // pageTheme={props?.home?.data?.site?.site_theme}
                                  pageTheme="theme4"
                                  isSRP={false}
                                  isOffline={false}
                                  homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                                  styles={FlightSearchTheme4CSS}
                                  b2bStyles={b2bWidgetStyle}
                                  calendarStyles={DesktopCalenderTheme4Css}
                                  getAutoSuggestData={(value, type, event) =>
                                    getAutoSuggestDataFunc(value, 'flight', type, event)
                                  }
                                  getGoingToSuggestionList={getGoingToSuggestionList}
                                  getDepartureSuggestionList={getDepartureSuggestionList}
                                  dateFormat={props?.home?.data?.site?.date_format}
                                  userProfile={userProfile}
                                  multiSelectDropdown={multiSelectDropdown}
                                  multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                                  selectFareTypeDropdown={selectFareTypeDropdown}
                                  multiSelectDepartureTimeDropdown={
                                    multiSelectDepartureTimeDropdown
                                  }
                                  multiSelectRBDClassDropdown={multiSelectRBDClassDropdown}
                                  multiSelectRBDClassDropdownTwo={multiSelectRBDClassDropdownTwo}
                                  multiSelectRBDClassDropdownOne={multiSelectRBDClassDropdownOne}
                                  selectedAirlines={airlineList}
                                  selectedSupplier={supplierList}
                                  selectedDepartureTime={selectedDepartureTime}
                                  selectedRbdClass={rbdOptions}
                                  selectedRbdClassOne={rbdOptionsOne}
                                  selectedRbdClassTwo={rbdOptionsTwo}
                                  SelectedClassValue={SelectedClassValue}
                                  DepartureTime={DepartureTime}
                                  FareType={SelectFareType}
                                  multiDepartureTimeDropDown={multiDepartureTimeDropDown}
                                  multiSelectDepartureTimeDropdownOne={
                                    multiSelectDepartureTimeDropdownOne
                                  }
                                  multiSelectDepartureTimeDropdownTwo={
                                    multiSelectDepartureTimeDropdownTwo
                                  }
                                  setrbdOptions={setrbdOptions}
                                  setrbdOptionsOne={setrbdOptionsOne}
                                  setrbdOptionsTwo={setrbdOptionsTwo}
                                  DepartureTimeOne={DepartureTimeOne}
                                  DepartureTimeTwo={DepartureTimeTwo}
                                  isMobile={props?.isMobile}
                                  modalCss={modalCss}
                                  siteData={props?.home?.data?.site}
                                />
                              )}
                            </div>{' '}
                            {/* Flight Desktop */}
                          </div>
                        )}

                      {SerachWidget === 'hotel' &&
                        !cf.isValueEmpty(res.type) &&
                        res.type == 'hotel_search' && (
                          <div
                            onClick={onClickWidgets}
                            className={widClicked ? styles.parentWid : ''}
                          >
                            <div
                              ref={flightWidRef}
                              onClick={onClickWidgets}
                              className={widClicked ? styles.childWid : ''}
                            >
                              <HotelSearchWidget
                                // pageTheme={props?.home?.data?.site?.site_theme}
                                pageTheme="theme4"
                                isSRP={false}
                                isOffline={false}
                                styles={DesktopHotelStylesTheme4}
                                b2bStyles={b2bWidgetStyle}
                                getAutoSuggestValue={(value) =>
                                  getAutoSuggestDataFunc(value, 'hotel')
                                }
                                expandHotelNameBoolean={expandHotelNameBoolean}
                                getDefaultDays={hotelDefultDays}
                                getAutoSuggestList={getHotelAutoSuggestList}
                                expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                                dateFormat={props?.home?.data?.site?.date_format}
                                userProfile={userProfile}
                                Countrystyles={Countrystyles}
                                modalCss={modalCss}
                              />
                            </div>
                          </div>
                        )}

                      {SerachWidget == 'activity' && res.type == 'activity_search' && (
                        <SearchWidgetActivity
                          {...props}
                          //  cssfile={cssfile}
                          cssfile={ActivityTheme4cssfile}
                          pageTheme={props?.home?.data?.site?.site_theme}
                          calendarStyles={ActivityTheme4cssfile}
                          dateFormat={props?.home?.data?.site?.date_format}
                          b2bStyles={b2bWidgetStyle}
                          isMobile={props?.isMobile}
                          Countrystyles={Countrystyles}
                          // popupcss={PopupCss}
                          isSrp={false}
                          userProfile={userProfile}
                          isOffline={false}
                        />
                      )}

                      {SerachWidget === 'holiday' && res.text == 'Holiday' && (
                        <SearchWidgetActivityNew
                          {...props}
                          //  cssfile={cssfile}
                          cssfile={ActivityTheme2cssfileNew}
                          pageTheme={props?.home?.data?.site?.site_theme}
                          calendarStyles={DesktopCalenderThemeOneCss}
                          dateFormat={props?.home?.data?.site?.date_format}
                          b2bStyles={b2bWidgetStyle}
                        />
                      )}

                      {SerachWidget === 'car' && res.type == 'car_search' && (
                          <div>
                            <CarSearchWidget
                              pageTheme="theme4"
                              isSRP={false}
                              isOffline={false}
                              homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                              styles={carWidgetCss}
                              b2bStyles={b2bWidgetStyle}
                              calendarStyles={DesktopCalenderTheme4Css}
                              getAutoSuggestData={(value, type, event) =>
                                getAutoSuggestDataFunc(value, 'car', type, event)
                              }
                              getGoingToSuggestionList={getCarReturnSuggestionList}
                              getDepartureSuggestionList={getCarDepartureSuggestionList}
                              getCarStopsSuggestionList={getCarStopsSuggestionList}
                              dateFormat={props?.home?.data?.site?.date_format}
                              userProfile={userProfile}
                              multiSelectDropdown={multiSelectDropdown}
                              multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                              selectFareTypeDropdown={selectFareTypeDropdown}
                              multiSelectDepartureTimeDropdown={multiSelectDepartureTimeDropdown}
                              multiSelectRBDClassDropdown={multiSelectRBDClassDropdown}
                              multiSelectRBDClassDropdownTwo={multiSelectRBDClassDropdownTwo}
                              multiSelectRBDClassDropdownOne={multiSelectRBDClassDropdownOne}
                              selectedAirlines={airlineList}
                              selectedSupplier={supplierList}
                              selectedDepartureTime={selectedDepartureTime}
                              selectedRbdClass={rbdOptions}
                              selectedRbdClassOne={rbdOptionsOne}
                              selectedRbdClassTwo={rbdOptionsTwo}
                              SelectedClassValue={SelectedClassValue}
                              DepartureTime={DepartureTime}
                              FareType={SelectFareType}
                              multiDepartureTimeDropDown={multiDepartureTimeDropDown}
                              multiSelectDepartureTimeDropdownOne={
                                multiSelectDepartureTimeDropdownOne
                              }
                              multiSelectDepartureTimeDropdownTwo={
                                multiSelectDepartureTimeDropdownTwo
                              }
                              setSelectFareType={setSelectFareType}
                              multicityFlag={res.multicity_required}
                              isMobile={props?.isMobile}
                            />
                          </div>
                        )}
                    </div>
                  )
                })}
              </div>
            ) : (
              <>
                <div className="main_body">
                  {homeBodyMobile.map((res, i) => {
                    return (
                      <div key={'homeBodyMbl' + i}>
                        {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                          <div className="img_banner_homeMobile4">
                            <Image
                              loader={imageLoader}
                              src={res.banners}
                              width={500}
                              alt=""
                              layout="fill"
                              priority
                            />
                            {/* <img src={ '/shared/api/media/' + props.site_id + '/' + res.banners} /> */}
                          </div>
                        )}

                        {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                          <div>
                            <Carousel
                              swipeable={false}
                              draggable={false}
                              showDots={true}
                              responsive={responsive}
                              ssr={false} // means to render carousel on server-side.
                              infinite={true}
                              autoPlay={props.isMobile ? true : true}
                              autoPlaySpeed={3000}
                              keyBoardControl={true}
                              customTransition="all .5"
                              transitionDuration={500}
                              containerClass="carousel-container4"
                              // deviceType={this.props.deviceType}
                              dotListClass="custom-dot-list-style"
                              itemClass="carousel-item-padding-40-px"
                            >
                              {!cf.isValueEmpty(res) &&
                                !cf.isValueEmpty(res.banners) &&
                                res.banners.length > 0 &&
                                res.banners.map((imagedata, index) => {
                                  return (
                                    <div key={'banner' + index} className="img_banner_homeMobile4">
                                      <CustomLink href={imagedata.banner_url}>
                                        <Image
                                          loader={imageLoader}
                                          src={imagedata.r_image}
                                          width={500}
                                          alt=""
                                          layout="fill"
                                          priority
                                        />
                                      </CustomLink>
                                      {/* <img src={`${ '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} /> */}
                                    </div>
                                  )
                                })}
                            </Carousel>
                          </div>
                        )}
                      </div>
                    )
                  })}
                  <div className="header-bannertxt">
                    <h2>Let's The World Together!</h2>
                    <p>Find awesome hotel, flight in Dubai</p>
                  </div>
                  <div className="all_sectionWdgt">
                    {!cf.isValueEmpty(props) &&
                      !cf.isValueEmpty(props.home) &&
                      !cf.isValueEmpty(props.home.data) &&
                      !cf.isValueEmpty(props.home.data.data) &&
                      !cf.isValueEmpty(props.home.data.data.header) &&
                      !cf.isValueEmpty(props.home.data.data.header.menu) &&
                      props.home.data.data.header?.mobile?.menu?.map(
                        (link, i) =>
                          link?.status.toLowerCase() === 'active' && (link.text.toLowerCase() != 'offline' || link.text.toLowerCase() == 'offline' && props?.home?.data?.data?.user?.user_type == 'TD') && (
                            <div key={'headerMenu' + i} className="section_divService4">
                              {link?.text?.toLowerCase() == 'explore saudi' ? (
                                <div
                                  onClick={() => {
                                    window.location.href = link.url
                                  }}
                                >
                                  <img
                                    src={`${
                                      '/shared/api/media/' + props.site_id + '/' + link.icon
                                    }`}
                                    alt=""
                                  />
                                  <label> {link.text}</label>
                                </div>
                              ) : (
                                <div onClick={() => onClickHeaderMenu(link, i)}>
                                  <img
                                    src={`${
                                      configUrl.BASE_URL +
                                      '/shared/api/media/' +
                                      props.site_id +
                                      '/' +
                                      link.icon
                                    }`}
                                    alt=""
                                  />
                                  <label> {link.text}</label>
                                  {/* <Link
                            key={link.text}
                            // href={link.url}
                            onClick={() => { window.location.href = link.url }}
                            className=""
                         >
                            {link.text}
                            <img src={`${ '/shared/api/media/' + "6124e1368ede4f08bf2d9ad4" + '/' + link.icon}`} alt="" />
                         </Link> */}
                                </div>
                              )}
                            </div>
                          )
                      )}
                    {configUrl?.ORG_ID == props?.home?.data?.data?.user?.organisation?.id && (
                      <div key={'headerMenuAncillary'} className="section_divService4">
                        <div onClick={() => { router.push('/myprofile?Linkindex=19') }}>
                          <img
                            src={'/images/ancillary-g-white.svg'}
                            alt=""
                          />
                          <label>{'Book Ancillary'}</label>
                        </div>
                      </div>
                    )}
                    {menuData && isPopup && renderInfo(menuData)}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* cards & other data starts */}

          <div>
            {homeBodyDesktop.map((res, i) => {
              return (
                <div key={'homeBodyDsktp' + i}>
                  <div>
                    {!cf.isValueEmpty(res.html)
                      ? parse(res.html)
                      : // <Interweave content={res.html} />
                        ''}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* cards & other data  end */}
      </>
    </>
  )
}

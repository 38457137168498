import Header1 from "./theme1";
import Header2 from "./theme2";
import Header4 from "./theme4";
import Header5 from "./theme5";
import Header7 from "./theme7";
import commonFunction from "../../lib/utils/common";

export function MobileHeader(props) {
  const { theme } = props;
  console.log("==>theme props", theme, props);
  switch (theme) {
    case "theme1":
      return <Header1 data={props} />;
      break;
    case "theme2":
      return <Header2 data={props} />;
      break;
    case "theme4":
      return <Header4 data={props} />;
      break;
    case "theme5":
      return <Header5 data={props} />;
      break;
    case "theme7":
      return <Header7 data={props} />;
      break;
    default:
      return <Header1 data={props} />;
  }
}

export default MobileHeader;

import Theme1 from "./theme1";
import Theme2 from "./theme2";
import Theme4 from "./theme4";
import Theme5 from "./theme5";
import Theme7 from "./theme7";
import ModifySearch from "./theme1/ModifySearch";
import ModifySearchTheme2 from "./theme2/ModifySearch";
import ModifySearchTheme4 from "./theme4/ModifySearch";

export default function FlightSearchWidget(props) {
  console.log("propspropspropsprops FlightSearchWidget", props);
  const theme = props.pageTheme;

  switch (theme) {
    case "theme1":
      if (props.isSRP) {
        return <ModifySearch {...props} />;
      } else {
        return <Theme1 {...props} />;
      }
    case "theme2":
      if (props.isSRP) {
        return <ModifySearchTheme2 {...props} />;
      } else {
        return <Theme2 {...props} />;
      }
    case "theme4":
      if (props.isSRP) {
        return <ModifySearchTheme4 {...props} />;
      } else {
        return <Theme4 {...props} />;
      }
    case "theme5":
      return <Theme5 {...props} />;
    case "theme7":
      return <Theme7 {...props} />;

    default:
      // if (props.isSRP) {
      //     return <ModifySearch {...props} />
      // } else {

      //     return <Theme2 {...props}  />
      // }

      return <Theme5 {...props} />;
  }
}

import React from "react";

class DistanceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: "",
      show: false,
      pkgId: props.packageId || 1,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const distances = this.renderDistances();
    if (distances.length > 0) {
      if (!this.props.distance) {
        this.props.apply("distance", distances[0]);
      } else {
        this.setState({ distance: this.props.distance });
      }
      // this.setState({ distance: this.props.distance ? this.props.distance : distances[0] });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        show: false,
      });
    }
  };
  renderDistances() {
    const distances = [];
    for (let i = 1; i <= this.props.count; i++) {
      distances.push(`${i} hrs ${i * 10} kms`);
    }
    return distances;
  }

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  handleSelectDistance = (dist, index) => {
    this.setState({ distance: dist, show: false, pkgId: index }, () => {
      if (!this.props.isMobile) {
        this.props.apply("distance", this.state.distance);
        this.props.onChangeDistance(index);
      }
    });
  };

  onApply = () => {
    const { apply, popupClosed, onChangeDistance } = this.props;
    apply("distance", this.state.distance);
    onChangeDistance(this.state.pkgId);
    popupClosed();
  };

  render() {
    return (
      <div ref={this.wrapperRef} className={this.props.styles.package_selector}>
        <div className={this.props.styles.package_selector__display_wrap} onClick={
              this.props.isMobile
                ? this.props.isAutoCompleteExpand
                : this.handleClick
            }>
          <div className={this.props.styles.package_selector__display}>
            <h3 className={this.props.styles.package_selector__title}>
              <span className={this.props.styles.package_selector__label}>
                {this.props.header}
              </span>
              <span className={this.props.styles.package_selector__value}>
                {this.props.distance}
              </span>
            </h3>
          </div>
          <span className={this.props.styles.arrow_icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
            >
              <path
                d="M17.1827 1.27698C16.6377 0.727984 15.7538 0.727984 15.2078 1.27698L8.79475 7.74198L2.38375 1.27798C1.83875 0.727985 0.95475 0.727985 0.40875 1.27798C-0.13625 1.82798 -0.13625 2.71898 0.40875 3.26898L7.80375 10.722C8.21275 11.135 8.81375 11.239 9.31775 11.032C9.48975 10.963 9.65075 10.86 9.78975 10.72L17.1838 3.26698C17.7288 2.71698 17.7287 1.82698 17.1827 1.27698Z"
                fill="#266AAC"
              />
            </svg>
          </span>
        </div>

        {this.state.show && !this.props.isMobile && (
          <div className={this.props.styles.package_selector__dropdown}>
            <div className={this.props.styles.package_selector__options}>
              {this.renderDistances().map((distance, index) => (
                <div
                  key={index}
                  onClick={() => this.handleSelectDistance(distance, index + 1)}
                  className={`${this.props.styles.package_selector__option} ${
                    distance === this.props.distance
                      ? this.props.styles.active
                      : ""
                  }`}
                >
                  {distance}
                </div>
              ))}
            </div>
          </div>
        )}
        {this.props.expandAutoCompleteBool && this.props.isMobile && (
          <div className={this.props.styles.timepicker_popup}>
            <div className={this.props.styles.headerAuto}>
              <span
                onClick={() => {
                  this.props.popupClosed();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24">
                  <path
                    fillRule="evenodd"
                    fill="#FFF"
                    d="m13.444 3.241-8.815 8.747 8.817 8.749c.75.744.75 1.95 0 2.694a1.93 1.93 0 0 1-2.715 0L.566 13.343a1.89 1.89 0 0 1-.528-.99 1.891 1.891 0 0 1 .525-1.718L10.729.546a1.932 1.932 0 0 1 2.715 0 1.897 1.897 0 0 1 0 2.695z"
                  />
                </svg>
              </span>
              <div className={this.props.styles.header_title}>
                {this.props.headerTitle}
              </div>
            </div>
            <div className={this.props.styles.package_selector__dropdown}>
              <div
                className={`${this.props.styles.selectPackage_btngrp} ${this.props.styles.flight_way_btngrp}`}
              >
                {this.renderDistances().map((distance, index) => (
                  <label
                    key={index}
                    className={`${this.props.styles.radio_button} 
                    ${
                      distance === this.state.distance
                        ? this.props.styles.radio_active
                        : ""
                    }`}
                    onClick={() =>
                      this.handleSelectDistance(distance, index + 1)
                    }
                  >
                    <input
                      type="radio"
                      value={distance}
                      checked={distance === this.state.distance}
                    />
                    {distance}
                    <span></span>
                  </label>
                ))}
              </div>
            </div>
            <div className={this.props.styles.pickup_button_wrap}>
              <button
                className={this.props.styles.pickup_time__apply_button}
                onClick={this.onApply}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DistanceList;

import IsdCountryDropdown1 from "./theme1";
import IsdCountryDropdown2 from "./theme2";
import IsdCountryDropdown5 from "./theme5";
import IsdCountryDropdown7 from "./theme7";

export default function IsdCountryDropdown(props) {
  // const theme= props?.theme
  const theme = "theme5";
  switch (theme) {
    case "theme1":
      return <IsdCountryDropdown1 {...props} />;
    case "theme2":
      return <IsdCountryDropdown2 {...props} />;
    case "theme5":
      return <IsdCountryDropdown5 {...props} />;
    case "theme7":
      return <IsdCountryDropdown7 {...props} />;
    default:
      return <IsdCountryDropdown1 {...props} />;
  }
}

import { useContext, useEffect, useState } from 'react'
import AssistedBooking from '../../common/components/assistedBooking/index'
import AssistedStyles from '../../common/components/assistedBooking/theme1/index.module.css'
import AssistedStylestwo from '../../common/components/assistedBooking/theme4/index.module.css'
import AssistedMobile from '../../common/components/assistedBooking/theme4/mobile.module.css'
import AuthContext from 'store/auth-context'
import Countrystyles from '../../common/components/CountryDropdown/theme1/index.module.css'
import Footer from '@/commonComponents/footer/index'
import Head from 'next/head'
import Header from '@/commonComponents/header/index'
import HideHeader from 'store/hideHeader'
import ISDstyles from '../../common/components/IsdCountryDropdown/theme1/index.module.css'
import ISDstylesTheme5 from '../../common/components/IsdCountryDropdown/theme5/index.module.css'
import ISDstylesTheme7 from '../../common/components/IsdCountryDropdown/theme7/index.module.css'
import LoginComponent from '../../common/components/login/index'
import MobileHeader from '@/commonComponents/mobileHeader/index'
import MobileSubFooter from '@/commonComponents/mobileSubFooter/index'
import MobileSubHeader from '@/commonComponents/mobileSubHeader/index'
import ModalPopupComponent from '@/commonComponents/ModalPopupComponent/ModalPopupComponent'
import SectionContainer from './SectionContainer'
import cf from '../../common/lib/utils/common'
import { checkregistertoken } from '../lib/checkregistertoken'
import { checksession } from '../../common/lib/checksession'
import configUrl from '../../config/config.json'
import cssfilefooter from '../../common/components/footer/footer1/index.module.css'
import cssfilefootertheme2 from '../../common/components/footer/footer2/index.module.css'
import cssfilefootertheme4 from '../../common/components/footer/footer4/index.module.css'
import cssfilefootertheme5 from '../../common/components/footer/footer5/index.module.css'
import cssfilefootertheme7 from '../../common/components/footer/footer7/index.module.css'
import cssfileheader from '../../common/components/header/theme1/index.module.css'
import cssfileheadertheme2 from '../../common/components/header/theme2/index.module.css'
import cssfileheadertheme4 from '../../common/components/header/theme4/index.module.css'
import cssfileheadertheme5 from '../../common/components/header/theme5/index.module.css'
import cssfileheadertheme7 from '../../common/components/header/theme7/index.module.css'
import cssfilemobileheader from '../../common/components/mobileHeader/theme1/index.module.css'
import cssfilemobileheadertheme2 from '../../common/components/mobileHeader/theme2/index.module.css'
import cssfilemobileheadertheme4 from '../../common/components/mobileHeader/theme4/index.module.css'
import cssfilemobileheadertheme5 from '../../common/components/mobileHeader/theme5/index.module.css'
import cssfilemobileheadertheme7 from '../../common/components/mobileHeader/theme7/index.module.css'
import cssfilemobilesubfooter from '../../common/components/mobileSubFooter/MobileSubFooter1/index.module.css'
import cssfilemobilesubheader from '../../common/components/mobileSubHeader/mobileSubHeader1/index.module.css'
import loginB2B from '../../common/lib/auth/b2bauth'
import logincss from '../../common/components/login/Theme1/theme1/index.module.css'
import logincssTheme4 from '../../common/components/login/Theme4/theme1/index.module.css'
import logincssTheme5 from '../../common/components/login/Theme5/theme1/index.module.css'
import logincssTheme7 from '../../common/components/login/theme7/index.module.css'
import menucssfile from '../components/Menu/index.module.css'
import menucssfileTheme4 from '../components/Menu/theme4/index.module.css'
import menucssfileTheme5 from '../components/Menu/theme5/index.module.css'
import mobileCountrystyles from '../../common/components/CountryDropdown/theme1/mobindex.module.css'
import mobileISDstyles from '../../common/components/IsdCountryDropdown/theme1/mobindex.module.css'
import mobileISDstylesTheme5 from '../../common/components/IsdCountryDropdown/theme5/mobindex.module.css'
import mobileISDstylesTheme7 from '../../common/components/IsdCountryDropdown/theme7/mobindex.module.css'
import mobilelogincss from '../../common/components/login/Mobile/theme1/index.module.css'
import mobilelogincssTheme5 from '../../common/components/login/Mobile/theme5/index.module.css'
import modalCss from '../../common/components/ModalPopupComponent/index.module.css'
import modalCssTheme5 from '../../common/components/ModalPopupComponent/theme5/index.module.css'
import modalCssTheme7 from '../../common/components/ModalPopupComponent/theme7/index.module.css'
import PageSeo from '../../common/PageSeo'
import siteMetadata from '@/data/siteMetadata'
import { useRouter } from 'next/router'
import googleTransalationCss from '../components/googleTranslate/index.module.css'
import GoogleTranslate from '../../common/components/googleTranslate/custom'
import IsdCodeDropdownCss from '@/commonComponents/IsdCodeDropdown/index.module.css'
import ModalCssMobile from '@/commonComponents/ModalPopupComponent/theme5mobile/index.module.css'

const LayoutWrapper = ({ children, sessionData }) => {
  console.log('=======LayoutWrapper shared children.props===', children.props)
  const [state, setState] = useState({
    headerPopup: false,
    popupType: '',
    emailid: '',
    user: {},
    isLoggedIn: false,
    isLoading: true,
  })
  const [assistedBokingPopup, setAssistedBokingPopup] = useState(false)
  const [assistedBookingData, setAssistedBookingData] = useState({})

  const router = useRouter()
  let routerurl = ['/quotation']
  let restrctedUrl = ['/signin']
  const urls = [
    '/signin',
    '/myprofile',
    '/page/[index]',
    '/',
    '/[index]',
    '/hotels',
    '/hotel',
    '/flight',
    '/flights',
    '/activities',
    '/holiday',
    '/holidays',
    '/experiences',
    '/offline',
    '/voucher',
    '/visa',
  ]
  const isValidUrl = urls.includes(router.pathname)
  // console.log("router.pathname",router);
  const urlValue = router.pathname == '/' ? 'holidays' : router.query.index

  useEffect(() => {
    // console.log("===>",urlValue);
    //app type store in localstorge for mobile aap landing
    //console.log('==== localStorage appType : ', localStorage.getItem('appType'))
    if (!localStorage.getItem('appType') && router.query && router.query.appType == 'mobileapp') {
      localStorage.setItem('appType', 'mobileapp')
      //console.log('====set localStorage appType')
    }

    // console.log(
    //   '==== localStorage nativeView : ',
    //   localStorage.getItem('nativeView'),
    //   'router?.query',
    //   router?.query
    // )
    if (
      !localStorage.getItem('nativeView') &&
      router?.query &&
      router?.query?.nativeview == 'true'
    ) {
      localStorage.setItem('nativeView', 'true')
      //console.log('====set localStorage nativeView')
    }

    var device_type = 'desktop'
    if (children?.props?.isMobile) {
      device_type = localStorage.getItem('appType') ? 'mobile_app' : 'mobile'
    }
    //console.log('===device_type', device_type)
    localStorage.setItem('deviceType', device_type)
    //console.log('==== localStorage deviceType',localStorage.getItem('deviceType'))

    // added custom css from mo
    if (children?.props?.home?.data?.data?.css) {
      const style = document.createElement('style')
      style.innerHTML = children?.props?.home?.data?.data?.css
      document.head.appendChild(style)
    }

    //check sessionData
    //console.log('==sessionData=', sessionData)
    if (sessionData) {
      //console.log('==sessionData exist=')
      setState((prevState) => {
        return {
          ...prevState,
          user: {
            ...sessionData,
            userType: sessionData.type,
          },
          isLoggedIn: true,
          isLoading: false,
        }
      })

      // restrict user after login
      if (restrctedUrl.includes(router.pathname)) {
        cf.redirectTo('/')
      }
    } else {
      //console.log('==sessionData not exist=')
      if (children?.props?.home?.data?.site?.business_b2b) {
        // if (routerurl.indexOf(router.pathname) == -1) {
        //   loginB2B(children)
        // }
        if (!routerurl.includes(router.pathname)) {
          loginB2B(children)
        }
      }
      setState((prevState) => {
        return {
          ...prevState,
          user: {},
          isLoading: false,
        }
      })
    }

    // checksession()
    //   .then((response) => {
    //     console.log('==checksession layout==', response)
    //     if (response.status) {
    //       setState((prevState) => {
    //         return {
    //           ...prevState,
    //           user: {
    //             ...response,
    //             userType: response.type,
    //           },
    //           isLoggedIn: true,
    //           isLoading: false,
    //         }
    //       })

    //       // restrict user after login
    //       if (restrctedUrl.includes(router.pathname)) {
    //         cf.redirectTo('/')
    //       }
    //     } else {
    //       if (children?.props?.home?.data?.site?.business_b2b) {
    //         // if (routerurl.indexOf(router.pathname) == -1) {
    //         //   loginB2B(children)
    //         // }
    //         if (!routerurl.includes(router.pathname)) {
    //           loginB2B(children)
    //         }
    //       }
    //       setState((prevState) => {
    //         return {
    //           ...prevState,
    //           user: {},
    //           isLoading: false,
    //         }
    //       })
    //     }
    //   })
    //   .catch((err) => {
    //     setState((prevState) => {
    //       return {
    //         ...prevState,
    //         user: {},
    //         isLoading: false,
    //       }
    //     })
    //     console.log(err.msg)
    //   })

    //verificationtoken
    if (
      router.query &&
      router.query.verificationtoken &&
      router.query.verificationtoken !== '' &&
      router.query.verificationtoken !== null
    ) {
      let requestData = {
        registraion_token: router.query.verificationtoken,
      }
      checkregistertoken(requestData).then((res) => {
        console.log('registration token res', res)
        if (res?.status && res?.registraion_token_status) {
          setState((prevState) => {
            return {
              ...prevState,
              emailid: res?.email || '',
              headerPopup: true,
              popupType: 'SignUp',
            }
          })
        }
      })
    }
    if (
      router.query &&
      router.query.resettoken &&
      router.query.resettoken !== '' &&
      router.query.resettoken !== null
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          headerPopup: true,
          popupType: 'ChangePassword',
        }
      })
    }
  }, [urlValue])

  useEffect(() => {
    if (state.headerPopup) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [state.headerPopup])

  const HideHeaderTheme2 = useContext(HideHeader)
  const themeType = children?.props?.home?.data?.site?.site_theme
  // const themeType = "theme5"

  //Header Footer show/hide
  const seoPageData = children.props?.seoPagesData ? children.props?.seoPagesData : null
  var isSeoPageFooter = children.props?.seoPagesData
    ? children.props?.seoPagesData?.show_footer
    : true

  if (themeType == 'theme5') {
    isSeoPageFooter = true
  }

  if (themeType == 'theme4' && window.location.pathname == '/login') {
    isSeoPageFooter = false
  }

  // handle header ====>

  var isSeoPageHeader = true

  if (children.props?.seoPagesData) {
    isSeoPageHeader = children.props?.seoPagesData?.show_header == false ? false : true
  } else {
    if (children?.props?.isMobile) {
      isSeoPageHeader =
        children?.props?.home?.data?.data?.header?.mobile?.is_show_header == false ? false : true
    } else {
      isSeoPageHeader =
        children?.props?.home?.data?.data?.header?.is_show_header == false ? false : true
    }
  }

  const handleDataReceived = (data) => {
    console.log('Data received from child:', data)
    if (data) {
      setAssistedBookingData(data)
      setAssistedBokingPopup(true)
    }

    // Do something with the received data in the parent component
  }

  return (
    <SectionContainer>
      {/* PageSeo */}
      <PageSeo children={children} />
      {children?.props?.home?.data?.site?.allow_multilingual && (
        <GoogleTranslate
          style={googleTransalationCss}
          isMobile={false}
          closeTranslationModal={''}
          isHomePage={false}
        />
      )}
      {!state.isLoading && (
        <AuthContext.Provider
          value={{
            isLoggedIn: state.isLoggedIn,
            user: state.user,
          }}
        >
          {!children.props.isMobile ? (
            window.location.pathname !== '/login' && window.location.pathname !== '/quotation' ? (
              <div className="desktop-layoutwrapper shared-offileheader new-homepage">
                {/* Header Section for Theme1 starts */}

                {/* {<Header
                theme={children?.props?.home?.data?.site?.site_theme} 
                cssfile={cssfileheader}
                siteData={children?.props?.home?.data}
                headermenu={!cf.isValueEmpty(children) && !cf.isValueEmpty(children.props)
                  && !cf.isValueEmpty(children.props.home) && !cf.isValueEmpty(children.props.home.data) && !cf.isValueEmpty(children.props.home.data.data) &&
                  !cf.isValueEmpty(children.props.home.data.data.header) &&
                  children.props.home.data.data.header
                }
                sessionData={!cf.isValueEmpty(sessionData) ? sessionData : ''}
                site_id={children?.props?.home?.data?.data?.site_id || ""}
                onClickHeaderLogin={(data) => {
                  setState(prevState => {
                    return {
                      ...prevState,
                      headerPopup: true,
                      popupType: data
                    }
                  })
                }}
                user={state.user}
              />} */}

                {/* Header Section for Theme2 starts */}
                {isSeoPageHeader && (
                  <Header
                    theme={themeType}
                    cssfile={
                      themeType == 'theme7'
                        ? cssfileheadertheme7
                        : themeType == 'theme5'
                          ? cssfileheadertheme5
                          : themeType == 'theme4'
                            ? cssfileheadertheme4
                            : themeType == 'theme2'
                              ? cssfileheadertheme2
                              : cssfileheader
                    }
                    HideHeaderTheme2={HideHeaderTheme2}
                    siteData={children?.props?.home?.data}
                    isMobile={children?.props?.isMobile}
                    headermenu={
                      !cf.isValueEmpty(children) &&
                      !cf.isValueEmpty(children.props) &&
                      !cf.isValueEmpty(children.props.home) &&
                      !cf.isValueEmpty(children.props.home.data) &&
                      !cf.isValueEmpty(children.props.home.data.data) &&
                      !cf.isValueEmpty(children.props.home.data.data.header) &&
                      children.props.home.data.data.header
                    }
                    sessionData={!cf.isValueEmpty(sessionData) ? sessionData : ''}
                    site_id={children?.props?.home?.data?.data?.site_id || ''}
                    // onClickHeaderLoginB2C={(data) => {
                    //   cf.redirectTo('/signin', {s: signinvalue})
                    // }}
                    // setSigninvalue={setSigninvalue}
                    onClickHeaderLogin={(data) => {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          headerPopup: true,
                          popupType: data,
                        }
                      })
                    }}
                    user={state.user}
                    onDataReceived={handleDataReceived}
                    query={urlValue}
                    showTranslation={true}
                    googleTransalationCss={googleTransalationCss}
                  />
                )}

                {state.headerPopup ? (
                  <LoginComponent
                    loginTheme={themeType}
                    directOpenLinkPopup={state.popupType}
                    clearDirectOpenLinkPopup={() => {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          popupType: '',
                        }
                      })
                    }}
                    siteData={children?.props?.home?.data}
                    registrationToken={router.query.verificationtoken}
                    resetToken={router.query.resettoken}
                    logincss={
                      themeType == 'theme7'
                        ? logincssTheme7
                        : themeType == 'theme5'
                          ? logincssTheme5
                          : themeType == 'theme4'
                            ? logincssTheme4
                            : logincss
                    }
                    ISDstyles={
                      themeType == 'theme5'
                        ? ISDstylesTheme5
                        : themeType == 'theme7'
                          ? ISDstylesTheme7
                          : ISDstyles
                    }
                    Countrystyles={Countrystyles}
                    setToken={(data) => {
                      setLoginToken(data)
                    }}
                    emailId={state.emailid}
                    modalCss={
                      themeType == 'theme7'
                        ? modalCssTheme7
                        : themeType == 'theme5'
                          ? modalCssTheme5
                          : modalCss
                    }
                    onClickCross={() => {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          headerPopup: false,
                        }
                      })
                    }}
                    ModalCssMobile={ModalCssMobile}
                    IsdCodeDropdownCss={IsdCodeDropdownCss}
                  />
                ) : null}

                {assistedBokingPopup ? (
                  <AssistedBooking
                    styles={
                      children?.props?.home?.data?.site?.site_theme == 'theme1'
                        ? AssistedStyles
                        : AssistedStylestwo
                    }
                    data={assistedBookingData}
                    setAssistedBokingPopup={setAssistedBokingPopup}
                    theme={children?.props?.home?.data?.site?.site_theme}
                    isMobile={children?.props?.isMobile}
                    user={state.user}
                  />
                ) : null}

                {children?.props?.home?.data?.site?.site_theme == 'theme2' ? (
                  <main className="">{children}</main>
                ) : (
                  <main className={isSeoPageHeader == false ? '' : 'pt-16'}>{children}</main>
                )}

                {/* Footer section for Theme1 */}

                {/* {<Footer
                theme={children?.props?.home?.data?.site?.site_theme} 
                cssfile={cssfilefooter}
                siteData={children?.props?.home?.data}
                footermenu={
                  !cf.isValueEmpty(children) && !cf.isValueEmpty(children.props)
                  && !cf.isValueEmpty(children.props.home) && !cf.isValueEmpty(children.props.home.data) && !cf.isValueEmpty(children.props.home.data.data) &&
                  !cf.isValueEmpty(children.props.home.data.data.footer) &&
                  children.props.home.data.data.footer
                }
                site_id={children?.props?.home?.data?.data?.site_id || ""}
              />} */}

                {/* Footer section for Theme1 */}
                {isSeoPageFooter && (
                  <Footer
                    theme={themeType}
                    cssfile={
                      themeType == 'theme7'
                        ? cssfilefootertheme7
                        : themeType == 'theme5'
                          ? cssfilefootertheme5
                          : themeType == 'theme4'
                            ? cssfilefootertheme4
                            : themeType == 'theme2'
                              ? cssfilefootertheme2
                              : cssfilefooter
                    }
                    siteData={children?.props?.home?.data}
                    footermenu={
                      !cf.isValueEmpty(children) &&
                      !cf.isValueEmpty(children.props) &&
                      !cf.isValueEmpty(children.props.home) &&
                      !cf.isValueEmpty(children.props.home.data) &&
                      !cf.isValueEmpty(children.props.home.data.data) &&
                      !cf.isValueEmpty(children.props.home.data.data.footer) &&
                      children.props.home.data.data.footer
                    }
                    site_id={children?.props?.home?.data?.data?.site_id || ''}
                    isMobile={children?.props?.isMobile}
                  />
                )}
              </div>
            ) : (
              <main className="">{children}</main>
            )
          ) : (
            <div className="mobile-layoutwrapper">
              {isSeoPageHeader && (
                <MobileHeader
                  theme={themeType}
                  cssfile={
                    themeType == 'theme7'
                      ? cssfilemobileheadertheme7
                      : themeType == 'theme5'
                        ? cssfilemobileheadertheme5
                        : themeType == 'theme4'
                          ? cssfilemobileheadertheme4
                          : themeType == 'theme2'
                            ? cssfilemobileheadertheme2
                            : cssfilemobileheader
                  }
                  menucssfile={
                    themeType == 'theme5'
                      ? menucssfileTheme5
                      : themeType == 'theme4'
                        ? menucssfileTheme4
                        : menucssfile
                  }
                  siteData={children?.props?.home?.data}
                  headermenu={
                    !cf.isValueEmpty(children) &&
                    !cf.isValueEmpty(children.props) &&
                    !cf.isValueEmpty(children.props.home) &&
                    !cf.isValueEmpty(children.props.home.data) &&
                    !cf.isValueEmpty(children.props.home.data.data) &&
                    !cf.isValueEmpty(children.props.home.data.data.header) &&
                    children.props.home.data.data.header
                  }
                  site_id={children?.props?.home?.data?.data?.site_id || ''}
                  onClickHeaderLogin={() => {
                    if (router.pathname != '/') {
                      cf.redirectTo('/')
                    } else {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          headerPopup: true,
                        }
                      })
                    }
                  }}
                  onClickHeaderLoginB2C={(data) => {
                    cf.redirectTo('/signin')
                  }}
                  pathname={router.pathname}
                  user={state.user}
                  assistedcss={AssistedMobile}
                  isMobile={children?.props?.isMobile}
                  modalCss={modalCssTheme5}
                  googleTransalationCss={googleTransalationCss}
                  showTranslation={true}
                />
              )}
              {/* <MobileSubHeader
            theme="theme1"
            cssfile={cssfilemobilesubheader}
            headermenu={
              !cf.isValueEmpty(children) && !cf.isValueEmpty(children.props)
              && !cf.isValueEmpty(children.props.home) && !cf.isValueEmpty(children.props.home.data) && !cf.isValueEmpty(children.props.home.data.data) &&
              !cf.isValueEmpty(children.props.home.data.data.header) &&
              children.props.home.data.data.header
            }
          /> */}

              {state.headerPopup && window.location.pathname !== '/login' ? (
                <LoginComponent
                  loginTheme={themeType}
                  isMobile={true}
                  directOpenLinkPopup={state.popupType}
                  clearDirectOpenLinkPopup={() => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        popupType: '',
                      }
                    })
                  }}
                  siteData={children.props.home.data}
                  registrationToken={router.query.verificationtoken}
                  resetToken={router.query.resettoken}
                  logincss={
                    themeType == 'theme7'
                      ? logincssTheme7
                      : themeType == 'theme5'
                        ? logincssTheme5
                        : mobilelogincss
                  }
                  ISDstyles={
                    themeType == 'theme5'
                      ? mobileISDstylesTheme5
                      : themeType == 'theme7'
                        ? mobileISDstylesTheme7
                        : mobileISDstyles
                  }
                  Countrystyles={mobileCountrystyles}
                  setToken={(data) => {
                    setLoginToken(data)
                  }}
                  emailId={state.emailid}
                  modalCss={
                    children?.props?.home?.data?.site?.site_theme == 'theme7'
                      ? modalCssTheme7
                      : children?.props?.home?.data?.site?.site_theme == 'theme5'
                        ? modalCssTheme5
                        : modalCss
                  }
                  onClickCross={() => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        headerPopup: false,
                      }
                    })
                  }}
                  ModalCssMobile={ModalCssMobile}
                  IsdCodeDropdownCss={IsdCodeDropdownCss}
                />
              ) : null}

              {themeType == 'theme5' ? (
                <main className="">{children}</main>
              ) : (
                <main className={isSeoPageHeader == false ? '' : 'pt-16'}>{children}</main>
              )}
              {isSeoPageFooter && (
                <Footer
                  theme={themeType}
                  cssfile={
                    themeType == 'theme7'
                      ? cssfilefootertheme7
                      : themeType == 'theme5'
                        ? cssfilefootertheme5
                        : themeType == 'theme4'
                          ? cssfilefootertheme4
                          : themeType == 'theme2'
                            ? cssfilefootertheme2
                            : cssfilefooter
                  }
                  siteData={children?.props?.home?.data}
                  footermenu={
                    !cf.isValueEmpty(children) &&
                    !cf.isValueEmpty(children.props) &&
                    !cf.isValueEmpty(children.props.home) &&
                    !cf.isValueEmpty(children.props.home.data) &&
                    !cf.isValueEmpty(children.props.home.data.data) &&
                    !cf.isValueEmpty(children.props.home.data.data.footer) &&
                    children.props.home.data.data.footer
                  }
                  site_id={children?.props?.home?.data?.data?.site_id || ''}
                  isMobile={children?.props?.isMobile}
                />
              )}
              {/* <MobileSubFooter
                theme="theme1"
                cssfile={cssfilemobilesubfooter}
                footermenu={
                  !cf.isValueEmpty(children) && !cf.isValueEmpty(children.props)
                  && !cf.isValueEmpty(children.props.home) && !cf.isValueEmpty(children.props.home.data) && !cf.isValueEmpty(children.props.home.data.data) &&
                  !cf.isValueEmpty(children.props.home.data.data.footer) &&
                  children.props.home.data.data.footer
                }
              /> */}
              {/* <MobileFooter
            theme="theme1"
            cssfile={cssfilemobilefooter}
            footermenu={
              !cf.isValueEmpty(children) && !cf.isValueEmpty(children.props)
              && !cf.isValueEmpty(children.props.home) && !cf.isValueEmpty(children.props.home.data) && !cf.isValueEmpty(children.props.home.data.data) &&
              !cf.isValueEmpty(children.props.home.data.data.footer) &&
              children.props.home.data.data.footer
            }
          /> */}
            </div>
          )}
        </AuthContext.Provider>
      )}
    </SectionContainer>
  )
}

export default LayoutWrapper

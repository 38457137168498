import Footer1 from "./footer1";
import Footer2 from "./footer2";
import Footer4 from "./footer4";
import Footer5 from "./footer5";
import Footer7 from "./footer7";
import commonFunction from "../../lib/utils/common";

export function Footer(props) {
  const { theme } = props;

  switch (theme) {
    case "theme1":
      return (
        !commonFunction.isValueEmpty(props) &&
        Object.keys(props).length > 0 && <Footer1 data={props} />
      );
      break;
    case "theme2":
      return (
        !commonFunction.isValueEmpty(props) &&
        Object.keys(props).length > 0 && <Footer2 data={props} />
      );
      break;
    case "theme4":
      return (
        !commonFunction.isValueEmpty(props) &&
        Object.keys(props).length > 0 && <Footer4 data={props} />
      );
      break;
    case "theme5":
      return (
        !commonFunction.isValueEmpty(props) &&
        Object.keys(props).length > 0 && <Footer5 data={props} />
      );
      break;
    case "theme7":
      return (
        !commonFunction.isValueEmpty(props) &&
        Object.keys(props).length > 0 && <Footer7 data={props} />
      );
      break;
    default:
      return <Footer1 />;
  }
}

export default Footer;

import React, { Component } from 'react';
import { checksession } from "../../../lib/checksession";

class TripTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingType: [
        { name: "business", value: "Business", label: "Business", tripId: 1, selected: false, show: false },
        { name: "annual", value: "Annual Trip", label: "Annual Trip", tripId: 2, selected: false, show: false },
        { name: "personal", value: "Personal", label: "Personal", tripId: 3, selected: false, show: false },
        { name: "exceptional", value: "Exceptional", label: "Exceptional", tripId: 4, selected: false, show: false },
      ],
      bookingTypeId: this.props.bookingTypeId||"",
    };
  }

  componentDidMount() {
    this.bookingTypeComp();
    this.bookingTypeMethod(this.state.bookingTypeId) 

  }

  bookingTypeComp = async () => {
    try {
      const response = await checksession();
      if (response.status) {
        const { corporate_detail } = response;
        const updatedBookingType = this.state.bookingType.map((item) => ({
          ...item,
          show: corporate_detail?.et_details?.[item.name]?.status || false,
        }));

        const firstVisibleType = updatedBookingType.find((item) => item.show);
        if (firstVisibleType) {
          firstVisibleType.selected = true;
          this.setState({
            bookingType: updatedBookingType,
            bookingTypeId: firstVisibleType.tripId,
          });
        }
      }
    } catch (err) {
      console.error(err.msg);
    }
  };

  bookingTypeMethod = (id) => {
    const updatedBookingType = this.state.bookingType.map((item) => ({
      ...item,
      selected: item.tripId === id,
    }));
    this.setState({
      bookingType: updatedBookingType,
      bookingTypeId: id,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.bookingTypeId !== this.state.bookingTypeId) {
      this.props.selectedTripId(this.state.bookingTypeId);
    }
  }

  render() {
    const { bookingType } = this.state;
    const { styles } = this.props;
    return (
      <div className={styles.flight_way_btngrp}>
        <div className={styles.trip_type_container}>
        <span className={styles.trip_type_heading}>Trip Type:</span>
          {bookingType.map((item) => (
            item.show && (
              <span className={styles.trip_radio_group} key={item.tripId}>
                <input
                  type="radio"
                  name={item.name}
                  value={item.value}
                  label={item.label}
                  checked={item.selected}
                  onClick={() => this.bookingTypeMethod(item.tripId)}
                />
                {item.label}
              </span>
            )
          ))}
        </div>
      </div>
    );
  }
}

export default TripTypeSelector;
 const theme_mobile = {
    mode: "main",
    palette: {
      primary: {
        main: "#4967db",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#000000",
        contrastText: "#ffffff",
      },
      error: {
        main: "#d32f2f",
        contrastText: "#ffffff",
      },
      success: {
        main: "green",
        contrastText: "#ffffff",
      },
      warning: {
        main: "orange",
        contrastText: "#ffffff",
      },
      info: {
        main: "blue",
        contrastText: "#ffffff",
      },
    },
    shape: {
      borderRadius: "3px 3px",
    },
    typography: {
      fontFamily: "'Open Sans',sans-serif",
      lineHeight: "32.68px",
      letterSpacing: "2px",
      title: {
        fontSize: "18px",
        fontWeight: "600",
      },
      heading: {
        fontSize: "24px",
        fontWeight: "800",
      },
      text: {
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  };

  module.exports = theme_mobile
import Theme1 from "./theme1";
import Theme2 from "./theme2";
import Theme4 from "./theme4";

export default function SearchWidget(props) {
  const theme = props.pageTheme;
  switch (theme) {
    case "theme1":
      // console.log("ACTIVITY-THEME-1 PROPS",props);
      return <Theme1 {...props} />;
    case "theme2":
      return <Theme2 {...props} />;

    case "theme4":
      return <Theme4 {...props} />;
    default:
      return <Theme1 {...props} />;
  }
}

import React, { Component, createRef } from "react";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import { logout } from "../../../lib/logout";
import Menu from "../../Menu/index";
import {
  setTockenInCoockie,
  getCookieTocken,
  removeUserTokenFromCookie,
} from "../../login/userToken";
import { checksession } from "../../../lib/checksession";
import Link from "../../../Link";
import chat from "../../../lib/chat";
import UploadLogo from "store/uploadLogo";
import GoogleTranslate from "../../googleTranslate/custom.js";
import ModalPopupComponent from "@/commonComponents/ModalPopupComponent/theme5/ModalPopupComponent";

// let MenuList=[
//     {name:'My Profile',href:'profile', activeimg:'/images/my-profile-g.svg',inactiveimg:'/images/my-profile-g.svg'},
//     {name:'My Booking',href:'mybooking',activeimg:'/images/my-booking-g.svg',inactiveimg:'/images/my-booking-g.svg'},
//     {name:'Guest & Traveler',href:'guesttraveler', activeimg:'/images/guestTravel-g.svg',inactiveimg:'/images/guestTravel-g.svg/images/prefernce-g.svg'},
//     {name:'Travel Preferences',href:'travelpreferences', activeimg:'/images/prefernce-g.svg',inactiveimg:'/images/prefernce-g.svg'},
//     {name:'Communication Preferences',href:'compreferences', activeimg:'/images/prefernce-g.svg',inactiveimg:'/images/prefernce-g.svg'},
//     {name:'My Frequent Flyer',href:'frequentflyer', activeimg:'/images/flyer-g.svg',inactiveimg:'/images/flyer-g.svg'},
//     {name:'Email Notifications',href:'emailnotifications', activeimg:'/images/Email-notifications-g.svg',inactiveimg:'/images/Email-notifications-g.svg'},
//     {name:'Change Password',href:'changepassword', activeimg:'/images/changePwd-g.svg',inactiveimg:'/images/changePwd-g.svg'},
//     {name:'Logout',href:'Logout', activeimg:'/images/Logout-g.svg',inactiveimg:'/images/Logout-g.svg'}
//   ]

let MenuList = [
  {
    Linkindex: 0,
    isPartition: false,
    linkItem: "My Profile",
    isActiveLink: true,
    componentName: "ProfileForm",
    imgInActiveSrc: "/images/my-profile-g.svg",
    imgActiveSrc: "/images/my-profile-g.svg",
    href: "profile",
  },
  {
    Linkindex: 1,
    isPartition: false,
    linkItem: "My Booking",
    isActiveLink: false,
    componentName: "BookingForm",
    imgInActiveSrc: "/images/my-booking-g.svg",
    imgActiveSrc: "/images/my-booking-g.svg",
    href: "mybooking",
  },
  {
    Linkindex: 2,
    isPartition: false,
    linkItem: "Guest & Traveler",
    isActiveLink: false,
    componentName: "GuestTravelerForm",
    imgInActiveSrc: "/images/guestTravel-g.svg",
    imgActiveSrc: "/images/guestTravel-g.svg",
    href: "guesttraveler",
  },
  {
    Linkindex: 3,
    isPartition: false,
    linkItem: "Travel Preferences",
    isActiveLink: false,
    componentName: "TravelPreferencesForm",
    imgInActiveSrc: "/images/prefernce-g.svg",
    imgActiveSrc: "/images/prefernce-g.svg",
    href: "travelpreferences",
  },
  // { Linkindex: 4, isPartition: false, linkItem: 'Communication Preferences', isActiveLink: false, componentName: 'CompreferencesForm', imgInActiveSrc: '/images/prefernce-g.svg', imgActiveSrc: '/images/prefernce-g.svg', href:'compreferences' },
  // { Linkindex: 5, isPartition: false, linkItem:'Coupons & More', isActiveLink: false,  componentName: 'CouponsMoreForm', imgInActiveSrc: '/images/coupon-g.svg', imgActiveSrc: '/images/coupon-g.svg' },
  {
    Linkindex: 6,
    isPartition: false,
    linkItem: "My Frequent Flyer",
    isActiveLink: false,
    componentName: "FrequentFlyerForm",
    imgInActiveSrc: "/images/flyer-g.svg",
    imgActiveSrc: "/images/flyer-g.svg",
    href: "frequentflyer",
  },
  // { Linkindex: 7, isPartition: false, linkItem:'My Wallet', isActiveLink: false,  componentName: 'WalletForm', imgInActiveSrc: '/images/wallet-g.svg', imgActiveSrc: '/images/wallet-g.svg' },
  // { Linkindex: 8, isPartition: false, linkItem:'Payments & More', isActiveLink: false,  componentName: 'PaymentsMoreForm', imgInActiveSrc: '/images/payment-g.svg', imgActiveSrc: '/images/payment-g.svg' },
  // { Linkindex: 9, isPartition: false, linkItem: 'Email notifications', isActiveLink: false, componentName: 'EmailNotificationsForm', imgInActiveSrc: '/images/Email-notifications-g.svg', imgActiveSrc: '/images/Email-notifications-g.svg',href:'emailnotifications' },
  {
    Linkindex: 10,
    isPartition: false,
    linkItem: "Change Password",
    isActiveLink: false,
    componentName: "ChangePasswordForm",
    imgInActiveSrc: "/images/changePwd-g.svg",
    imgActiveSrc: "/images/changePwd-g.svg",
    href: "changepassword",
  },
  {
    Linkindex: 11,
    isPartition: false,
    linkItem: "Logout",
    isActiveLink: false,
    componentName: "Logout",
    imgInActiveSrc: "/images/Logout-g.svg",
    imgActiveSrc: "/images/Logout-g.svg",
    href: "Logout",
  },
];

class MobileHeader1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToken: "",
      isShowPopup: false,
      userFirststname: "",
      userLaststname: "",
      open: false,
      scrolling: false,
      isTranslatePopup: false,
      language: "en",
    };
    this.supportWrapperRef = createRef();
  }

  setSupportWrapperRef = (node) => {
    this.supportWrapperRef = node;
  };

  handleCalendarClickOutside = (event) => {
    if (
      !this.supportWrapperRef ||
      !this.supportWrapperRef?.contains(event.target)
    ) {
      this.setState({ openSupportPopup: false });
    }
  };

  componentDidMount() {
    /* this.setState({
            newToken: getCookieTocken("userToken") !== undefined && getCookieTocken("userToken") !== null && getCookieTocken("userToken") !== "" && getCookieTocken("userToken")
        }/* , () => {

            this.state.newToken && this.checkSessionApi()
        } )*/
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleCalendarClickOutside);
    if (this.props?.data?.siteData?.site?.allow_multilingual) {
      let googtransCookie = commonFunction.getCookie("googtrans");
      let language =
        googtransCookie != "" ? googtransCookie.split("/")[2] : "en";
      this.setState({ language });
    }
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);
    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userLaststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.last_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        newToken: this.props?.data?.user?.accessToken || "",
      });
    } else {
      this.setState({
        userFirststname: "",
        userLaststname: "",
        newToken: "",
      });
    }

    // console.log("newTokennewTokennewTokennewToken",this.state.newToken)
  }
  checkSessionApi = () => {
    // console.log("checkSessionApicheckSessionApi called")
    // let checksessionreq = {
    //     "site_id": "6124e1368ede4f08bf2d9ad4",
    //     "accessToken": this.state.newToken
    // }
    // checksession(checksessionreq).then((responseJSON) => {
    //     if (responseJSON.status === true && !commonFunction.isValueEmpty(responseJSON.first_name)) {
    //         let firstChar = responseJSON.first_name.charAt(0)
    //         this.setState({
    //             userFirststname: firstChar
    //         }, () => {
    //         })
    //     } else {
    //     }
    // }).catch(err => {
    //     console.log("error", err)
    // })
  };
  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };
  onMouseLeave = () => {
    // console.log("onMouseLeaveonMouseLeave")
    this.setState({
      isShowPopup: false,
    });
  };

  // componentWillReceiveProps(state, props) {
  //     this.setState({
  //         newToken: getCookieTocken("userToken") !== undefined && getCookieTocken("userToken") !== null && getCookieTocken("userToken") !== "" && getCookieTocken("userToken")
  //     }, () => {
  //         this.state.newToken && this.checkSessionApi()
  //     })
  // }
  onClickProfile = () => {
    commonFunction.redirectTo("/myprofile");
  };
  onClickLogout = () => {
    // let logoutreq = {
    //     "site_id": "6124e1368ede4f08bf2d9ad4",
    //     "accessToken": this.state.newToken
    // }
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status &&
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            newToken: "",
            userFirststname: "",
            userLaststname: "",
          });
          location.reload();
          // commonFunction.redirectTo('/')
          // } else {
          //     this.setState({});
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
    // this.setState({
    // isToken=''
    // })
    // this.props.data.setLoginTokenData=""
  };

  onClickMenu = (bool) => {
    this.setState({ open: bool });
  };

  onClickTab = (id) => {
    // console.log("id=========>", id);
    let newdata = MenuList.map((fact, index) => {
      if (id == index) {
        let data = {
          ...fact,
          active: true,
        };
        return data;
      } else {
        let data = {
          ...fact,
          active: false,
        };
        return data;
      }
    });
    MenuList = newdata;
    this.setState({ open: false });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
  };

  handleScroll = (event) => {
    // console.log("clicked");
    if (window.scrollY <= 100 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
      // console.log("false");
    } else if (window.scrollY > 100 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
      // console.log("true");
    }
  };

  render() {
    console.log("mobile heder=====>", this.props?.data);
    return (
      <header className="mobile-header theme5">
        {/* <div>Mobile Header
            <div className={props.data.cssfile.main_logo_header}>
                <div className={props.data.cssfile.logo_header}>
                <img  className={props.data.cssfile.header_logo} src={`${'/shared/api/media/6124e1368ede4f08bf2d9ad4/'+props.data.headermenu.logo}`} alt="logo" />

                </div>
                </div>

                <div className={styles.logosub_header}>
                    <img className={styles.search_icon} src="../images/searchOption.png" />
                    <img className={styles.call_icon} src="./images/callOption.png" />
                    <img className={styles.login_icon} src="../images/profileOption.png" />

                </div>
            </div> */}

        {this.props.data.pathname === "/myprofile" ? null : (
          <div
            className={`${this.props.data.cssfile.main_logo_header}  ${
              this.state.scrolling === true
                ? this.props.data.cssfile.header_stickyMobile
                : ""
            }`}
          >
            <div className={this.props.data.cssfile.logosub_header}>
              {/* <img className={this.props.data.cssfile.search_icon} src="../images/searchOption.png" /> */}
              {/* {(this.props?.data?.headermenu?.is_show_login == undefined || this.props?.data?.headermenu?.is_show_login) && <a href="tel:+971 58 953 9988"><h2><img className={this.props.data.cssfile.call_icon} src="images/call_-01.svg" alt="Call" /></h2></a>} */}
              {/* <img className={this.props.data.cssfile.login_icon} src="../images/profileOption.png" onClick={this.props.data.onClickHeaderLogin} /> */}
              {this.props?.data?.headermenu?.is_show_login == undefined ||
              this.props?.data?.headermenu?.is_show_login ? (
                this.state.newToken != undefined &&
                this.state.newToken != null &&
                this.state.newToken != "" ? (
                  <div
                    className={this.props.data.cssfile.afterlogin}
                    onClick={() => {
                      window.location.href = "/myprofile";
                    }}
                  >
                    <span
                      className={this.props.data.cssfile.user_code}
                      onClick={() => {
                        this.onClickMenu(!this.state.open);
                      }}
                    >
                      {this.state.userFirststname}
                      {this.state.userLaststname}
                    </span>
                  </div>
                ) : (
                  <span
                    className={this.props.data.cssfile.login_btn}
                    onClick={this.props.data.onClickHeaderLogin}
                  >
                    {/* <img className={this.props.data.cssfile.login_icon} src="images/user-login-mob1.svg" onClick={this.props.data.onClickHeaderLogin} alt="" /> */}
                    <span onClick={this.props.data.onClickHeaderLogin}>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        width="200px"
                        height="200px"
                        viewBox="0 0 200 200"
                      >
                        <path
                          fill="#FFFFFF"
                          d="M101,53.479c-18.008,0-32.613,14.604-32.613,32.613c0,18.008,14.604,32.613,32.613,32.613  c18.009,0,32.612-14.604,32.612-32.613C133.612,68.083,119.009,53.479,101,53.479z"
                        ></path>
                        <path
                          fill="#FFFFFF"
                          d="M101,20.863c-45.028,0-81.536,36.505-81.536,81.539c0,45.028,36.508,81.536,81.536,81.536  c45.027,0,81.536-36.508,81.536-81.536C182.536,57.368,146.027,20.863,101,20.863z M35.771,102.401  c0-36.03,29.205-65.232,65.229-65.232c36.023,0,65.226,29.203,65.226,65.232c0,16.536-6.157,31.644-16.303,43.144  c-12.979-11.612-30.132-18.686-48.923-18.686c-18.792,0-35.944,7.073-48.916,18.686C41.932,134.045,35.771,118.944,35.771,102.401z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                )
              ) : (
                []
              )}
              {/* <Menu
                    theme={"theme5"}
                    clickedTab={this.onClickTab}
                    onclickLogout={this.onClickLogout}
                    styles={this.props.data.menucssfile}
                    isopen={this.state.open}
                    MenuList={MenuList}
                    id={"main-menu"}
                    pathname={
                      this.props.data.pathname === "/myprofile"
                        ? new URLSearchParams(window.location.search).get(
                            "Linkindex"
                          ) !== null
                          ? this.props.data.pathname
                          : "myprofile"
                        : this.props.data.pathname
                    }
                    closeMenusHandler={this.onClickMenu}
                  /> */}

              {/* {(this.state.newToken != undefined && this.state.newToken != null && this.state.newToken != "") ?
                              <div className={this.props.data.cssfile.afterlogin}> <span className={this.props.data.cssfile.user_code}>{this.state.userFirststname}</span>
                                  <span onClick={() => { this.onClickArrow() }} >
                                      <img src="https://uat.elevatetrips.com/image/down_arrow.jpg" alt="" />
                                  </span>

                                  <div className={this.state.isShowPopup ? this.props.data.cssfile.show : this.props.data.cssfile.profile_details_hide} onMouseLeave={() => { this.onMouseLeave() }}>
                                      <span onClick={() => { this.onClickProfile() }}> <img type="image" src="https://www.intermilesresources.com/sharedresources/img/svg/flyout/links/myprofile.svg" alt="" />My Profile</span>
                                      <span onClick={() => { this.onClickLogout() }}> <img type="image" src="https://www.intermilesresources.com/sharedresources/img/svg/flyout/links/logout.svg" alt="" />Logout</span>
                                      <span> <img type="image" src="https://uat.elevatetrips.com/image/et-support.png" alt="" />Support</span>

                                  </div>
                              </div> : 
                              <span className={this.props.data.cssfile.login_btn} onClick={this.props.data.onClickHeaderLogin}>
                                  <img className={this.props.data.cssfile.login_icon} src="../images/profileOption.png" onClick={this.props.data.onClickHeaderLogin} />

                              </span>} */}
              {this.state.newToken != undefined &&
                this.state.newToken != null &&
                this.state.newToken != "" && (
                  <p className={this.props.data.cssfile.welcome_text}>
                    {/* Hi  */}
                    {/* {this.props?.data?.user?.first_name} */}
                  </p>
                )}
            </div>
            <div className={this.props.data.cssfile.branchlogomobo}>
              <Link
                title="Logo"
                key={"logo"}
                href={"/"}
                // onclick="window.location.href='/'"
                //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
                className=""
              >
                <img
                  height="51"
                  width="81"
                  className={this.props?.data?.cssfile?.header_logo}
                  src={`${
                    "/shared/api/media/" +
                    this.props?.data?.site_id +
                    "/" +
                    (UploadLogo?._currentValue?.isLogo
                      ? this.props?.data?.headermenu?.mobile?.logo
                      : this.props?.data?.headermenu?.mobile?.logo)
                  }`}
                  alt="logo"
                />
              </Link>
            </div>
            <div className={this.props.data.cssfile.logo_header}>
              <ul className={this.props.data.cssfile.navbar_right}>
                <li
                  className={this.props.data.cssfile.dropdown}
                  onClick={() => this.setState({ isTranslatePopup: true })}
                  style={{
                    display: this.props.data.showTranslation ? "block" : "none",
                  }}
                >
                  <span className={this.props.data.cssfile.dropdown_span}>
                    <img
                      src="/images/india-flag.png"
                      alt="flag"
                      //    width="20px"
                      //    height="20px"
                    />
                    <div className={this.props.data.cssfile.znth_main}>
                      <span>
                        {this.state.language.toUpperCase()}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="7"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                          />
                        </svg>{" "}
                      </span>
                    </div>
                  </span>
                </li>
                {/* <li className={this.props.data.cssfile.navbar_newdropdown}>
                    <GoogleTranslate
                          style={this.props.data.cssfile.translate_element}
                        />       
                </li> */}

                <li
                  className={this.props.data.cssfile.support}
                  style={{ display: "none" }}
                >
                  <div
                    className={this.props.data.cssfile.suport_cont}
                    ref={this.setSupportWrapperRef}
                  >
                    <div
                      className={this.props.data.cssfile.znth_support}
                      onClick={() => {
                        this.setState({
                          openSupportPopup: !this.state.openSupportPopup,
                        });
                      }}
                    >
                      {/* <span>Support</span> */}
                      <span className={this.props.data.cssfile.dropZ_arrow}>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="26.75px"
                          height="26.75px"
                          viewBox="0 0 26.75 26.75"
                        >
                          <g>
                            <path
                              fill="#FFFFFF"
                              d="M15.711,13.257c-0.538,0.747-1.286,1.306-2.334,1.306c-3.201,0-3.644-5.16-3.646-5.183V9.094
		c0.438-0.259,1.067-0.737,1.574-1.345c1.343,1.224,4.473,1.449,5.717,1.491l0.001,0.111c-0.002,0.025-0.106,1.198-0.554,2.427
		c0.676-0.081,1.377-0.196,1.968-0.364c-0.206-1.773-0.435-3.455-0.438-3.481c-0.002-0.021-0.013-0.039-0.02-0.058
		c0.032-0.463,0.028-0.89-0.016-1.172c-0.465-3.069-4.558-3-4.581-3.003C13.346,3.695,9.252,3.64,8.788,6.703
		C8.744,6.984,8.741,7.411,8.772,7.875C8.765,7.894,8.754,7.912,8.751,7.933c-0.006,0.044-0.597,4.39-0.729,6.491
		c-0.006,0.1,0.042,0.195,0.125,0.252c0.047,0.031,0.103,0.047,0.155,0.047c0.044,0,0.086-0.01,0.125-0.028
		c0.012-0.008,1.043-0.512,2.017-0.93c0.653,0.889,1.592,1.56,2.933,1.56c1.34,0,2.277-0.671,2.931-1.561
		c0.972,0.419,2.004,0.923,2.016,0.931c0.039,0.019,0.083,0.028,0.124,0.028c0.055,0,0.108-0.016,0.156-0.047
		c0.084-0.057,0.132-0.151,0.126-0.252c-0.029-0.456-0.08-1.02-0.142-1.621C17.753,13.042,16.689,13.18,15.711,13.257z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M20.009,8.897c-0.137-0.065-0.288-0.086-0.445-0.087c0.001-0.03,0.004-0.062,0.004-0.092
		c0-3.375-1.053-6.225-6.191-6.225c-5.14,0-6.191,2.85-6.191,6.225c0,0.03,0.003,0.062,0.003,0.092
		C7.03,8.811,6.88,8.832,6.743,8.897c-0.584,0.279-1.06,1.42-0.5,2.619c0.391,0.834,1.171,0.705,1.619,0.543
		c0.031-0.287,0.065-0.573,0.099-0.858c-0.132-0.585-0.079-1.107,0.011-1.494l0.004-0.001c0-0.004-0.001-0.008-0.002-0.012
		C8.07,9.287,8.203,9.026,8.203,9.026C8.099,8.991,7.999,8.959,7.9,8.93C7.898,8.858,7.892,8.789,7.892,8.718
		c0-3.124,0.898-5.533,5.484-5.533c4.587,0,5.483,2.409,5.483,5.533c0,0.07-0.006,0.14-0.009,0.211
		c-0.099,0.03-0.199,0.061-0.301,0.097c0,0,0.132,0.26,0.228,0.667c0,0.005,0,0.008,0,0.015h0.004
		c0.114,0.494,0.166,1.211-0.148,1.996c-1.076,0.4-3.186,0.554-4.516,0.598c-0.169-0.158-0.432-0.266-0.74-0.266
		c-0.516,0-0.933,0.282-0.933,0.627c0,0.346,0.417,0.627,0.933,0.627c0.329,0,0.605-0.124,0.771-0.297
		c1.334-0.044,4.023-0.211,5.145-0.832c0.425,0.061,0.927-0.027,1.215-0.646C21.066,10.316,20.594,9.175,20.009,8.897z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M21.65,22.043c-0.577-1.974-1.057-4.33-1.925-5.195c-0.865-0.867-2.934-1.926-2.934-1.926
		c0.006,0.495-0.063,0.961-0.176,1.402c-1.866,1.573-4.614,1.574-6.481,0c-0.113-0.442-0.183-0.907-0.175-1.402
		c0,0-2.07,1.059-2.936,1.926c-0.865,0.865-1.348,3.222-1.925,5.195c-0.578,1.972,0.338,2.212,0.961,2.212h7.315h7.313
		C21.313,24.255,22.229,24.015,21.65,22.043z M13.377,20.634c0,0-1.667-1.063-2.672-2.884c0.836,0.424,1.752,0.639,2.67,0.639
		s1.835-0.216,2.672-0.639C15.042,19.571,13.377,20.634,13.377,20.634z M19.027,21.319h-3.082v-1.075h3.082V21.319z"
                            />
                          </g>
                        </svg>{" "}
                        ▾
                      </span>
                    </div>
                    {this.state.openSupportPopup && (
                      <div
                        className={this.props.data.cssfile.supportdropNew}
                        style={{ display: "block" }}
                      >
                        <a
                          href={
                            localStorage.getItem("appType") == "mobileapp"
                              ? "/mobileAppLink?page=supportNo&value=" +
                                this.props?.data?.siteData?.site?.support_number
                              : `tel:${this.props?.data?.siteData?.site?.support_number}`
                          }
                        >
                          <div
                            className={this.props.data.cssfile.header_menuZth}
                          >
                            {/* <img src="/images/et-hd-phn.png" alt="" /> */}
                            {/* mobile icon */}
                            <svg
                              fill="currentColor"
                              height="20px"
                              width="20px"
                              version="1.1"
                              id="Capa_1"
                              viewBox="0 0 473.806 473.806"
                            >
                              <g>
                                <g>
                                  <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4    c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8    c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6    c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5    c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26    c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9    c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806    C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20    c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6    c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1    c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3    c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5    c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8    c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9    l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1    C420.456,377.706,420.456,388.206,410.256,398.806z"></path>
                                  <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2    c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11    S248.656,111.506,256.056,112.706z"></path>
                                  <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11    c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2    c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"></path>
                                </g>
                              </g>
                            </svg>
                            {this.props?.data?.siteData?.site?.support_number}
                          </div>
                        </a>
                        <a
                          href={
                            localStorage.getItem("appType") == "mobileapp"
                              ? "/mobileAppLink?page=supportEmail&value=" +
                                this.props?.data?.siteData?.site?.support_email
                              : `mailto:${this.props?.data?.siteData?.site?.support_email}`
                          }
                        >
                          <div
                            className={this.props.data.cssfile.header_menuZth}
                          >
                            {/* <img
                              src="/images/et-hd-email.png"
                              alt=""
                              width="20px"
                              height="20px"
                            /> */}
                            <svg
                              height="20px"
                              width="20px"
                              version="1.1"
                              id="_x32_"
                              viewBox="0 0 512 512"
                              fill="currentColor"
                            >
                              <g>
                                <path
                                  class="st0"
                                  d="M510.678,112.275c-2.308-11.626-7.463-22.265-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345   c-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557,0-37.395,7.996-50.21,20.814c-1.715,1.715-3.301,3.43-4.823,5.345   C8.785,90.009,3.63,100.649,1.386,112.275C0.464,116.762,0,121.399,0,126.087V385.92c0,9.968,2.114,19.55,5.884,28.203   c3.497,8.26,8.653,15.734,14.926,22.001c1.59,1.586,3.169,3.044,4.892,4.494c12.286,10.175,28.145,16.32,45.319,16.32h369.958   c17.18,0,33.108-6.145,45.323-16.384c1.718-1.386,3.305-2.844,4.891-4.43c6.27-6.267,11.425-13.741,14.994-22.001v-0.064   c3.769-8.653,5.812-18.171,5.812-28.138V126.087C512,121.399,511.543,116.762,510.678,112.275z M46.509,101.571   c6.345-6.338,14.866-10.175,24.512-10.175h369.958c9.646,0,18.242,3.837,24.512,10.175c1.122,1.129,2.179,2.387,3.112,3.637   L274.696,274.203c-5.348,4.687-11.954,7.002-18.696,7.002c-6.674,0-13.276-2.315-18.695-7.002L43.472,105.136   C44.33,103.886,45.387,102.7,46.509,101.571z M36.334,385.92V142.735L176.658,265.15L36.405,387.435   C36.334,386.971,36.334,386.449,36.334,385.92z M440.979,420.597H71.021c-6.281,0-12.158-1.651-17.174-4.552l147.978-128.959   l13.815,12.018c11.561,10.046,26.028,15.134,40.36,15.134c14.406,0,28.872-5.088,40.432-15.134l13.808-12.018l147.92,128.959   C453.137,418.946,447.26,420.597,440.979,420.597z M475.666,385.92c0,0.529,0,1.051-0.068,1.515L335.346,265.221L475.666,142.8   V385.92z"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className={this.props.data.cssfile.translate_modal}>
          <ModalPopupComponent
            modalCss={this.props.data.modalCss}
            isPopup={this.state.isTranslatePopup}
            showCrossButton={false}
            onClosePopup={() => this.setState({ isTranslatePopup: false })}
          >
            <div>
              <div className={this.props.data.cssfile.header_blueband}>
                <div onClick={() => this.setState({ isTranslatePopup: false })}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                  >
                    <path
                      id="Vector"
                      d="M13.3755 0.174907L7.5 6.91054L1.62445 0.174907C1.42102 -0.0583024 1.09168 -0.0583024 0.888772 0.174907L0.152573 1.01887C-0.0508576 1.25208 -0.0508576 1.62963 0.152573 1.86225L6.02812 8.59788L0.152573 15.3335C-0.0508576 15.5667 -0.0508576 15.9443 0.152573 16.1769L0.888772 17.0209C1.0922 17.2541 1.42154 17.2541 1.62445 17.0209L7.5 10.2852L13.3755 17.0209C13.579 17.2541 13.9083 17.2541 14.1112 17.0209L14.8474 16.1769C15.0509 15.9437 15.0509 15.5661 14.8474 15.3335L8.97188 8.59788L14.8474 1.86225C15.0509 1.62904 15.0509 1.25149 14.8474 1.01887L14.1112 0.174907C13.9078 -0.0583024 13.5785 -0.0583024 13.3755 0.174907Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span>{"Select the Prefered Option"}</span>
              </div>
              <GoogleTranslate
                style={this.props.data.googleTransalationCss}
                isMobile={true}
                closeTranslationModal={() => {}}
                isHomePage={
                  this.props?.data?.siteData?.site?.allow_multilingual
                }
                site_multi_languages={
                  this.props?.data?.siteData?.site?.site_multi_languages
                }
              />

              <div className={this.props.data.cssfile.footer_contact}>
                <p>Connect with us</p>
                <div className={this.props.data.cssfile.flex_row}>
                  <div className={this.props.data.cssfile.contactinfo}>
                    <a
                      href={
                        localStorage.getItem("appType") == "mobileapp"
                          ? "/mobileAppLink?page=supportNo&value=" +
                            this.props?.data?.siteData?.site?.support_number
                          : `tel:${this.props?.data?.siteData?.site?.support_number}`
                      }
                    >
                      <span>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group 1261156451">
                            <circle
                              id="Ellipse 56"
                              cx="12.5"
                              cy="12.5"
                              r="12.5"
                              fill="#266AAC"
                            />
                            <path
                              id="Vector"
                              d="M6.44908 18H18.5509C19.3506 17.9994 19.9987 17.3726 20 16.5992V8.4008C19.9987 7.6274 19.3506 7.00061 18.5509 7H6.44908C5.64937 7.00061 5.00126 7.6274 5 8.4008V16.5992C5.00126 17.3726 5.64937 17.9994 6.44908 18ZM18.547 17.0225H6.4528L9.58968 12.5336C10.4008 13.392 11.1436 14.1776 11.6597 14.7267H11.6591C11.8802 14.9454 12.1828 15.0688 12.4993 15.0688C12.8157 15.0694 13.1189 14.946 13.34 14.7273L15.4107 12.533L18.547 17.0225ZM8.88801 11.792L6.01133 15.9089L6.0107 8.75394C6.8161 9.60371 7.86972 10.7156 8.88801 11.792ZM18.9893 8.75456V15.9089L16.112 11.7908L18.9893 8.75456ZM15.0887 11.4242L12.5935 14.0688V14.0682C12.5726 14.0987 12.5372 14.117 12.5 14.117C12.4621 14.117 12.4267 14.0987 12.4065 14.0675C10.905 12.4719 8.16092 9.57487 6.64617 7.97685L18.3544 7.97747C18.3342 7.99885 15.1056 11.4053 15.0886 11.4236L15.0887 11.4242Z"
                              fill="white"
                            />
                          </g>
                        </svg>{" "}
                        {this.props?.data?.siteData?.site?.support_number}{" "}
                      </span>{" "}
                    </a>
                  </div>
                  <div className={this.props.data.cssfile.contactinfo}>
                    <a
                      href={
                        localStorage.getItem("appType") == "mobileapp"
                          ? "/mobileAppLink?page=supportEmail&value=" +
                            this.props?.data?.siteData?.site?.support_email
                          : `mailto:${this.props?.data?.siteData?.site?.support_email}`
                      }
                    >
                      <span>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group 1261156450">
                            <circle
                              id="Ellipse 55"
                              cx="12.5"
                              cy="12.5"
                              r="12.5"
                              fill="#266AAC"
                            />
                            <g id="Group 1261156449">
                              <path
                                id="Vector"
                                d="M12.5018 5C12.2256 5 12.0017 5.22388 12.0017 5.50006C12.0017 5.77623 12.2256 6.00011 12.5018 6.00011C15.5397 6.00011 18.0024 8.46282 18.0024 11.5007C18.0024 11.7769 18.2262 12.0008 18.5024 12.0008C18.7786 12.0008 19.0025 11.7769 19.0025 11.5007C19.0025 7.91047 16.092 5 12.5018 5Z"
                                fill="white"
                              />
                              <path
                                id="Vector_2"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.58092 6.95777C7.97174 6.35231 6.98373 6.34451 6.37261 6.9519C4.86379 8.4515 4.50763 10.8321 5.76349 12.6276C7.43672 15.0199 9.16226 16.7625 11.3775 18.2775C13.1725 19.5051 15.5234 19.0965 17.005 17.624C17.6291 17.0036 17.6724 15.9937 17.0346 15.3598L15.6543 13.988C15.0596 13.3969 14.1032 13.3661 13.4779 13.9279L12.8069 14.5308C12.6223 14.6967 12.3596 14.7211 12.1624 14.6018C10.8593 13.8135 10.1363 13.088 9.35663 11.8136C9.23847 11.6205 9.26192 11.3634 9.42789 11.1809L10.0345 10.5141C10.6017 9.89048 10.5701 8.9348 9.97395 8.34231L8.58092 6.95777ZM7.07763 7.66124C7.29349 7.44671 7.65157 7.44416 7.8759 7.66711L9.26892 9.05163C9.49065 9.27201 9.49695 9.6187 9.29462 9.84117L8.68805 10.508C8.23866 11.002 8.1414 11.7436 8.5035 12.3355C9.36638 13.7461 10.2044 14.5861 11.6447 15.4575C12.2371 15.8158 12.9793 15.7204 13.4753 15.2747L14.1463 14.6718C14.3722 14.4688 14.7262 14.4756 14.9493 14.6974L16.3296 16.0692C16.5788 16.3169 16.5352 16.6808 16.3 16.9147C15.1084 18.0989 13.285 18.3704 11.942 17.452C9.83598 16.0117 8.19422 14.358 6.58303 12.0544C5.6348 10.6987 5.87671 8.85484 7.07763 7.66124Z"
                                fill="white"
                              />
                              <path
                                id="Vector_3"
                                d="M12.5018 9.00195C12.2256 9.00195 12.0017 9.22583 12.0017 9.50201C12.0017 9.77819 12.2256 10.0021 12.5018 10.0021C13.3303 10.0021 14.0019 10.6737 14.0019 11.5022C14.0019 11.7784 14.2258 12.0023 14.502 12.0023C14.7782 12.0023 15.002 11.7784 15.002 11.5022C15.002 10.1214 13.8826 9.00195 12.5018 9.00195Z"
                                fill="white"
                              />
                              <path
                                id="Vector_4"
                                d="M12.0017 7.50103C12.0017 7.22486 12.2256 7.00098 12.5018 7.00098C14.9873 7.00098 17.0023 9.0159 17.0023 11.5015C17.0023 11.7777 16.7784 12.0015 16.5022 12.0015C16.226 12.0015 16.0022 11.7777 16.0022 11.5015C16.0022 9.56826 14.435 8.00109 12.5018 8.00109C12.2256 8.00109 12.0017 7.7772 12.0017 7.50103Z"
                                fill="white"
                              />
                            </g>
                          </g>{" "}
                        </svg>{" "}
                        {this.props?.data?.siteData?.site?.support_email}{" "}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ModalPopupComponent>
        </div>
      </header>
    );
  }
}

export default MobileHeader1;

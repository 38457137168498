import React, { Component } from 'react';
import moment from 'moment'

class PickupTime extends Component {
  constructor(props) {
    super(props);
    const [hourMinute, period] = (props.time || '01:00 AM').split(' ');
    const [hour, minute] = hourMinute.split(':');
    this.state = {
      time: props.time || '01:00 AM',
      show: false,
      tempTime: props.time || '01:00 AM',
      selectedHour: hour,
      selectedMinute: minute,
      selectedPeriod: period,
      selectedDate: props.selectedDate
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ time: this.props.time, tempTime: this.props.time });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedDate !== nextProps.selectedDate) {
      this.setState({ selectedDate: nextProps.selectedDate }, () => {
        const currentDate = new Date();
        const selectedDate = new Date(this.state.selectedDate);
        const isSameDay = selectedDate.toDateString() === currentDate.toDateString();
        if (isSameDay) {
          this.updateTimeState(nextProps.time);
        }
      });
    }
    if (this.props.time !== nextProps.time) {
      this.updateTimeState(nextProps.time);
    }
  }

  // componentDidUpdate(prevProps) {
  //   console.log("prevProps====>", prevProps, "this.props====>", this.props, "this.state.show=>", this.state.show);
  //   if (this.state.show) {
  //     console.log("inside========>", this.props.time, typeof this.props.time, "=======>", prevProps.time, typeof prevProps.time, "comp=>", this.props.time !== prevProps.time);
  //     if (this.props.time !== prevProps.time) {
  //       this.updateTimeState(prevProps.time);
  //     }
  //   }
  // }

  updateTimeState(newTime) {
    const [hourMinute, period] = newTime.split(' ');
    const [hour, minute] = hourMinute.split(':');
    this.setState({
      time: newTime,
      tempTime: newTime,
      selectedHour: hour,
      selectedMinute: minute,
      selectedPeriod: period,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  isPastTime(selectedHour, selectedMinute, selectedPeriod) {
    const now = new Date();
    // now.setHours(now.getHours() + 1);
    now.setMinutes(now.getMinutes() + 15);
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentPeriod = currentHour >= 12 ? 'PM' : 'AM';

    let selectedTimeInMinutes = (parseInt(selectedHour) % 12) * 60 + parseInt(selectedMinute);
    let currentTimeInMinutes = (currentHour % 12) * 60 + currentMinute;

    if (selectedPeriod !== currentPeriod) {
      if (selectedPeriod === 'AM') {
        selectedTimeInMinutes += 720;
      } else {
        currentTimeInMinutes += 720;
      }
    }

    return selectedTimeInMinutes < currentTimeInMinutes;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      const [hourMinute, period] = (this.state.time || '01:00 AM').split(' ');
      const [hour, minute] = hourMinute.split(':');
      this.setState({
        show: false,
        tempTime: this.state.time,
        selectedHour: hour,
        selectedMinute: minute,
        selectedPeriod: period
      });
    }
  };

  handleTimeChange = (part, value) => {
    const [hour, minutePeriod] = this.state.tempTime.split(':');
    const [minute, period] = minutePeriod.split(' ');

    let newTime;
    if (part === 'hour') {
      newTime = `${value}:${minute} ${period}`;
      this.setState({ selectedHour: value });
    } else if (part === 'minute') {
      newTime = `${hour}:${value} ${period}`;
      this.setState({ selectedMinute: value });
    } else if (part === 'period') {
      newTime = `${hour}:${minute} ${value}`;
      this.setState({ selectedPeriod: value });
    }

    this.setState({
      tempTime: newTime,
    });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
      tempTime: prevState.time,
    }));
  };

  onApply = () => {
    const { type } = this.props;
    this.setState({
      time: this.state.tempTime,
      show: false,
    }, () => {
      const currentDate = new Date();
      const selectedDate = new Date(this.state.selectedDate);
      const isSameDay = selectedDate.toDateString() === currentDate.toDateString();
      if(isSameDay) {
        let curTime = moment();
        let roundedMinutes = Math.ceil(curTime.minute() / 5) * 5;
        curTime.minute(roundedMinutes).seconds(0);
        curTime.add(15, 'minutes');
        const currentDate = new Date(); 
        const currentTime = curTime.format("hh:mm A");
        const stateTimeMoment = moment(this.state.time, "hh:mm A");
        const currentTimeMoment = moment(currentTime, "hh:mm A");
        currentDate.setMinutes(currentDate.getMinutes() + 15);
        if(stateTimeMoment.isBefore(currentTimeMoment)) {
          this.setState({
            time: currentTime,
            tempTime: currentTime
          }, () => {
            this.props.apply(type, this.state.time);
            this.updateTimeState(this.state.time);
          });
        } else {
          this.props.apply(type, this.state.time);
          this.updateTimeState(this.state.time);
        }
      } else {
        this.props.apply(type, this.state.time);
        this.updateTimeState(this.state.time);
      }
    });
  };

  render() {
    const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    const minutes = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'));
    const periods = ['AM', 'PM'];
    const currentDate = new Date();
    const selectedDate = new Date(this.state.selectedDate);
    const isSameDay = selectedDate.toDateString() === currentDate.toDateString();
    currentDate.setMinutes(currentDate.getMinutes() + 15);
    const curHour = currentDate.getHours() >= 12 ? currentDate.getHours() % 12 : currentDate.getHours();
    const curMinute = currentDate.getMinutes();
    const curPeriod = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    return (
      <div ref={this.wrapperRef} className={this.props.styles.pickup_time}>
        <div onClick={this.handleClick} className={this.props.styles.pickup_time_wrap}>
          <div className={this.props.styles.pickup_time__display}>
            <h3 className={this.props.styles.pickup_time__title}>
              <span className={this.props.styles.pickup_time__label}>{this.props.label}</span>
              <span className={this.props.styles.pickup_time__value}>
                {this.state.tempTime}
              </span>
            </h3>
          </div>
          <span className="">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
              <path
                d="M17.1827 1.27698C16.6377 0.727984 15.7538 0.727984 15.2078 1.27698L8.79475 7.74198L2.38375 1.27798C1.83875 0.727985 0.95475 0.727985 0.40875 1.27798C-0.13625 1.82798 -0.13625 2.71898 0.40875 3.26898L7.80375 10.722C8.21275 11.135 8.81375 11.239 9.31775 11.032C9.48975 10.963 9.65075 10.86 9.78975 10.72L17.1838 3.26698C17.7288 2.71698 17.7287 1.82698 17.1827 1.27698Z"
                fill="#266AAC"
              />
            </svg>
          </span>
        </div>

        {this.state.show && (
          <div className={this.props.styles.pickup_time__selection}>
            <div className={this.props.styles.pickup_time__preview}>
              <span className={this.props.styles.pickup_time__temp_value}>
                {this.state.tempTime}
              </span>
              <button className={this.props.styles.pickup_time__apply_button} onClick={this.onApply}>
                Apply
              </button>
            </div>

            <div className={this.props.styles.pickup_time__preview_wrap}>
              <div className={`${this.props.styles.pickup_time__options} ${this.props.styles.pickup_time__options__hours}`}>
                {hours.map((hourOption) => {
                  const disabled = isSameDay && ((this.state.selectedPeriod == curPeriod && curHour > parseInt(hourOption)) || (curPeriod == 'AM' && hourOption == 12)) &&  this.isPastTime(hourOption, this.state.selectedMinute, this.state.selectedPeriod);
                  return (
                    <div
                      key={hourOption}
                      onClick={() => !disabled && this.handleTimeChange('hour', hourOption)}
                      className={`${this.props.styles.pickup_time__option} ${this.props.styles.pickup_time__option__hour} ${this.state.selectedHour === hourOption ? this.props.styles.active : ''} ${disabled ? this.props.styles.disabled : ''}`}
                    >
                      {hourOption} {' hrs'}
                    </div>
                  );
                })}
              </div>

              <div className={`${this.props.styles.pickup_time__options} ${this.props.styles.pickup_time__options__minutes}`}>
                {minutes.map((minuteOption) => {
                  const disabled = isSameDay && this.state.selectedPeriod == curPeriod && curHour >= parseInt(this.state.selectedHour) && minuteOption < parseInt(curMinute) // this.isPastTime(this.state.selectedHour, minuteOption, this.state.selectedPeriod);
                  return (
                    <div
                      key={minuteOption}
                      onClick={() => !disabled && this.handleTimeChange('minute', minuteOption)}
                      className={`${this.props.styles.pickup_time__option} ${this.props.styles.pickup_time__option__minute} ${this.state.selectedMinute === minuteOption ? this.props.styles.active : ''} ${disabled ? this.props.styles.disabled : ''}`}
                    >
                      {minuteOption} {' mins'}
                    </div>
                  );
                })}
              </div>

              <div className={`${this.props.styles.pickup_time__options} ${this.props.styles.pickup_time__options__periods}`}>
                {periods.map((periodOption) => {
                  const disabled = isSameDay && curPeriod != 'AM' && periodOption === 'AM' // && (currentDate.getMinutes() + 15 >= 60 ? currentDate.getHours() + 1 : currentDate.getHours()) >= 12;
                  return (
                    <div
                      key={periodOption}
                      onClick={() => !disabled && this.handleTimeChange('period', periodOption)}
                      className={`${this.props.styles.pickup_time__option} ${this.props.styles.pickup_time__option__period} ${this.state.selectedPeriod === periodOption ? this.props.styles.active : ''} ${disabled ? this.props.styles.disabled : ''}`}
                    >
                      {periodOption}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PickupTime;

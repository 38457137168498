import React, { Component } from "react";

// import B2BWidgets from "../../../b2bwidgets/index";
import autoSuggest from "../../../../lib/holidayautosuggest";
import categoryList from "../../../../lib/categoryList";
import cf from "../../../../lib/utils/common";
import { th } from "date-fns/locale";
import TripTypeSelector from "../../../../components/TripTypeSelector/theme4/index.js";

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query.length > 2) {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

const categoryArray = [];

export default class Theme4 extends Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.categoryRef = React.createRef();
    this.monthRef = React.createRef();
    this.state = {
      activityName: new URLSearchParams(window.location.search).has("searchby")
        ? new URLSearchParams(window.location.search).get("searchby").toString()
        : "",
      isAutoSuggestVisible: false,
      isCategoryVisible: false,
      isMonthVisible: false,
      // checkInOutValue: "",
      // checkInValue: null,
      // checkOutValue: null,
      // isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: [],
      selectedAutoSuggestData: {
        category: new URLSearchParams(window.location.search).has("category")
          ? new URLSearchParams(window.location.search).get("category") !== ""
            ? new URLSearchParams(window.location.search)
                .get("category")
                .split(",")
                .map((ele) => categoryArray.push({ name: ele }))
            : []
          : [],
        name: new URLSearchParams(window.location.search).has("searchby")
          ? new URLSearchParams(window.location.search)
              .get("searchby")
              .toString()
          : "",
        type: new URLSearchParams(window.location.search).has("type")
          ? new URLSearchParams(window.location.search).get("type").toString()
          : "",
      },
      isError: false,
      isDateError: false,
      lastArrowAction: "",
      selectedAutoSuggestIndex: -1,
      selectedMonthIndex: -1,
      noresults: false,
      categoryList: [],
      categoryValue: [],
      categoryOriginalArray: [],
      monthOriginalArray: cf.getMonthsRange(12),
      monthData: cf.getMonthsRange(12),
      monthValue: new URLSearchParams(window.location.search).has("month")
        ? new URLSearchParams(window.location.search).get("month").toString()
        : "",
      bookingType: [
          {
            name: "business",
            value: "Business",
            label: "Business",
            tripId: 1,
            selected: false,
            show: false,
          },
          {
            name: "annual",
            value: "Annual Trip",
            label: "Annual Trip",
            tripId: 2,
            selected: false,
            show: false,
          },
          {
            name: "personal",
            value: "Personal",
            label: "Personal",
            tripId: 3,
            selected: false,
            show: false,
          },
          {
            name: "exceptional",
            value: "Exceptional",
            label: "Exceptional",
            tripId: 4,
            selected: false,
            show: false,
          },
        ],
        isguestChecked: false,
        bookingTripId: parseInt(new URLSearchParams(window.location.search).get("bookingType"))||"",

    };
  }
  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };
  componentDidMount() {
    try {
      categoryList().then((res) => {
        if (res?.status) {
          this.setState({
            categoryList: res.data,
            categoryOriginalArray: res?.data,
          });
        } else {
          // Handle the 404 error here
          // For example, you can display an error message or redirect to a different page
          console.log("404 error occurred");
        }
      });
    } catch (e) {
      console.log(e);
    }

    if (new URLSearchParams(window.location.search).has("searchby")) {
      let text = new URLSearchParams(window.location.search)
        .get("searchby")
        .toString();
      if (text !== "") {
        autoSuggest({
          searchText: text,
        }).then((data) => {
          if (data?.data?.length > 0) {
            this.setState({
              categoryList: data.data[0].category,
              monthData: data.data[0].month,
            });
          }
        });
      }
    }
    this.setState({ categoryValue: categoryArray });
    const activityname = new URLSearchParams(window.location.search).get(
      "activityname"
    );
    // const holidayName = new URLSearchParams(window.location.search).has('searchby')
    // this.setState({activityname: holidayName ? new URLSearchParams(window.location.search).get('searchby').toString() : ''})
    // this.onChangeCheckInOutValue(new Date());
    if (activityname != null) {
      debouncedFetchData(activityname, (data) => {
        if (
          data &&
          data.data
          // typeof data.data.searchBycity === "object"
        ) {
          const cityData = [...data.data];
          const selectedCity = cityData.filter((city) => {
            return city.name == activityname;
          })[0];
          this.setState({
            autoSuggestCityData: cityData,
            isAutoSuggestVisible: false,
            activityName: selectedCity.name,
            selectedAutoSuggestData: selectedCity,
            isError: false,
            noresults: false,
          });
        }
        if (data.status && data.data.length === 0) {
          this.setState({
            noresults: true,
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
          });
        }
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //Add client
  // adGuestId = (id) => {
  //   this.setState({ guest_id: id }, () => {
  //     if (this.state.guestError === true) {
  //       this.setState({ guestError: cf.isValueEmpty(id) ? true : false })
  //     }
  //     this.setState({ guest_id: id });
  //   });
  // }
  // rmGuestId = () => {
  //   this.setState({ guest_id: "" });
  // };
  // onChangeCheckInOutValue = (dateValue) => {
  //   console.log("date=value", dateValue);
  //   this.setState({
  //     checkInValue: cf.getDateWithFormat(
  //       // new Date(dateValue),
  //       add(new Date(dateValue), { days: 7 }),
  //       this?.props?.dateFormat
  //     ),
  //     checkOutValue: cf.getDateWithFormat(
  //       add(new Date(dateValue), { days: 8 }),
  //       this?.props?.dateFormat
  //     ),
  //     checkInOutValue: cf.getDateWithFormat(dateValue, this?.props?.dateFormat),
  //     isDateError: false,
  //   });
  // };

  onChanegeSetActivityName = (value) => {
    this.setState({ activityName: value, isError: false });
    if (value === "") {
      this.setState({
        autoSuggestCityData: [],
        // isAutoSuggestVisible: false,
        selectedAutoSuggestData: [],
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data
          // data.data.searchBycity &&
          // typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data],
            isAutoSuggestVisible: true,
            noresults: false,
          });
        }
        if (data?.status && data?.data?.length === 0) {
          this.setState({
            noresults: true,
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
          });
        }
      });
    }
  };

  onClickSearchActivity = () => {
    let urlQuery = {
      searchby: this.state.activityName,
      type: this.state.selectedAutoSuggestData.hasOwnProperty("type")&&this.state.selectedAutoSuggestData?.type!=""? this.state.selectedAutoSuggestData.type: this.state.monthValue!=""?1:"",
      category: this.state.categoryValue.map(({ name }) => `${name}`).join(","),
      month: this.state.monthValue,
      bookingType: this.state.bookingTripId,

      // checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
      // checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
      // from: 1,
      // to: 30,
      // search_type: this.state.selectedAutoSuggestData.location_type,
      // search_text: this.state.selectedAutoSuggestData.destination_id,
      // currency: "AED",
      // rating: [...new Set(this.state.ratingSelected)].join(','),
      // guestId: this.state.guest_id
    };
    cf.redirectTo("/holiday/search", urlQuery);
    // if (this.props.isOffline === true) {
    // if (this.state.guest_id !== '') {
    //   const searchParams = new URLSearchParams(urlQuery)
    //   const queryString = searchParams.toString()
    //   // window.location = "/offlinesearch/activity?" + queryString
    //   window.location =  "/holiday/search"
    // }
    // window.open(`${window.location.origin}` + "/offlinesearch/activity" , "_self");
    // cf.redirectTo("/offlinesearch/activity", urlQuery)

    // } else {
    // window.location =  "/holiday/search"
    // }
    // }
    // else {
    //   console.log('please select guest id')
    // }
  };
  // onClickSetActivityName = (element) => {
  //   this.setState({
  //     activityName: element.name,
  //     selectedAutoSuggestData: element,
  //     isError: false,
  //   });
  // };
  onInputFocus = (fieldName) => {
    if (fieldName == "categories") {
      this.setState({
        isAutoSuggestVisible: false,
        isCategoryVisible: true,
        isMonthVisible: false,
      });
    } else if (fieldName == "month") {
      this.setState({
        isAutoSuggestVisible: false,
        isCategoryVisible: false,
        isMonthVisible: true,
      });
    } else if (fieldName == "search") {
      this.setState({ isMonthVisible: false });
    }
  };

  keyPress = (action) => {
    console.log('auto suggest holiday ==', action);
    switch (action) {
      case "ArrowDown":
        // console.log("keyPress fn down called ", action);
        let children = document.getElementsByName(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        );

        let selectIndex = 0;
        selectIndex =
          this.state.selectedAutoSuggestIndex == children.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex + 1;
        // console.log("children of auto-suggest", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedAutoSuggestIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        console.log("keyPress fn up called ", action);
        let childrens = document.getElementsByName(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        );
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedAutoSuggestIndex == childrens.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex == -1
            ? 0
            : this.state.selectedAutoSuggestIndex - 1;
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        // this.setState((prevState) => ({
        //   selectedAutoSuggestIndex:
        //     prevState.selectedAutoSuggestIndex > 0
        //       ? prevState.lastArrowAction=="ArrowDown"?prevState.selectedAutoSuggestIndex - 1:prevState.selectedAutoSuggestIndex
        //       : prevState.selectedAutoSuggestIndex+1,
        //   lastArrowAction: "ArrowUp",
        // }));
        this.setState({
          selectedAutoSuggestIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        } else if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.autoSuggestCityData[indexToSelect];
        this.setState({
          activityName: selectedItem.name,
          selectedAutoSuggestData: selectedItem,
          isAutoSuggestVisible: false,
          selectedAutoSuggestIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        console.log("default", action);
        break;
    }
  };
  keyPressMonth = (action) => {
    switch (action) {
      case "ArrowDown":
        let children = document.getElementById("MONTH-LIST").children;

        let selectIndex = 0;
        selectIndex =
          this.state.selectedMonthIndex == children.length - 1
            ? 0
            : this.state.selectedMonthIndex + 1;
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedMonthIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        let childrens = document.getElementById("MONTH-LIST").children;
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedMonthIndex == childrens.length - 1
            ? 0
            : this.state.selectedMonthIndex == -1
            ? 0
            : this.state.selectedMonthIndex - 1;
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedMonthIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedMonthIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedMonthIndex;
        } else if (
          this.state.selectedMonthIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedMonthIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.monthData[indexToSelect];
        this.setState({
          monthValue: selectedItem.month,
          isMonthVisible: false,
          selectedMonthIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        console.log("default", action);
        break;
    }
  };

  scrollToMyRef = () => {
    this.searchBoxRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  // onClickTraveller = () => {
  //   if (!this.state.openTraveler) {
  //     this.setState({ openTraveler: true })
  //   } else {
  //     this.setState({ openTraveler: false })
  //   }
  // }

  // handleStarCheck = (event) => {
  //   this.setState({ [event.target.name]: event.target.checked })
  // }

  handleClickOutside = (e) => {
    if (
      this.advanceSearchWrapper.current &&
      !this.advanceSearchWrapper.current.contains(e.target)
    ) {
      this.setState({ advSearch: false });
    }
    if (
      this.guestDetailWrapper.current &&
      !this.guestDetailWrapper.current.contains(e.target)
    ) {
      this.setState({ openTraveler: false });
    }
    if (
      this.categoryRef.current &&
      !this.categoryRef.current.contains(e.target)
    ) {
      this.setState({ isCategoryVisible: false });
    }
    if (this.monthRef.current && !this.monthRef.current.contains(e.target)) {
      this.setState({ isMonthVisible: false });
    }
  };

  categoryHandler = (e, ele) => {
    if (e.target.checked) {
      const isFound = this.state.categoryValue.some((element) => {
        if (element.name === ele.name) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        this.setState({ categoryValue: [...this.state.categoryValue, ele] });
      }
    } else {
      this.setState({
        categoryValue: this.state.categoryValue.filter((value) => {
          return value.name !== ele.name;
        }),
      });
    }
  };

  categorySelected = (item) => {
    let value = false;
    this.state.categoryValue.map((ele) => {
      if (ele.name === item.name) {
        value = true;
      }
    });
    return value;
  };


  selectedTripId = (id) => {
    this.setState({ bookingTripId: id });    
  }

  render() {
    return (
      <>
        {this.props.isMobile ? (
          <>
            <div
              className={
                this.props.isSrp
                  ? this.props.cssfile.holidayMain_srp
                  : "container mx-auto xl:px-8"
              }
              ref={this.searchBoxRef}
              onClick={(e) => {
                e.stopPropagation();
                if (this.state.isAutoSuggestVisible) {
                  this.setState({
                    isAutoSuggestVisible: false,
                  });
                }
                // if (this.state.isCalendarVisible) {
                //   this.setState({
                //     isCalendarVisible: false,
                //   });
                // }
              }}
            >
              <div className={this.props.cssfile.holiday_mainWidgetMob}>
                <div
                  className={this.props.cssfile.holiday_mainWidgetfixed}
                  id={this.props.cssfile.tab_submenu_content}
                >
                  <div
                    className={
                      this.props.cssfile.landing_tab_submenu_new +
                      " " +
                      this.props.cssfile.sub_tab_new
                    }
                  >
                    <div className={this.props.cssfile.mobile_visible}>
                      <div
                        className={
                          this.props.cssfile.mobo_tab_header +
                          " " +
                          this.props.cssfile.flight_header_mobo
                        }
                      >
                        <span
                          class={this.props.cssfile.arrow_image}
                          onClick={() => this.props.onClosePopup(false)}
                        >
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="200px"
                            height="200px"
                            viewBox="0 0 200 200"
                            stroke="currentColor"
                          >
                            <g>
                              <g>
                                <path
                                  fill="currentColor"
                                  d="M94.277,181.169c-3.199,0-6.398-1.22-8.839-3.661L16.77,108.839c-4.881-4.882-4.881-12.796,0-17.678 l68.668-68.668c4.882-4.882,12.797-4.882,17.678,0c4.882,4.881,4.882,12.796,0,17.678L43.287,100l59.83,59.83 c4.882,4.882,4.882,12.796,0,17.678C100.676,179.948,97.476,181.169,94.277,181.169z"
                                ></path>
                              </g>
                              <g>
                                <path
                                  fill="currentColor"
                                  d="M174.391,112.5H35.146c-6.903,0-12.5-5.597-12.5-12.5s5.597-12.5,12.5-12.5h139.245 c6.903,0,12.5,5.597,12.5,12.5S181.294,112.5,174.391,112.5z"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        {/* {this.props.isSrp ? <img src="/images/blue-backarrow.svg" alt="" className={this.props.cssfile.arrow_image} onClick={() => this.props.onClosePopup(false)} /> 
                                    : 
                                    <img src="/images/blue-backarrow.svg" alt="" className={this.props.cssfile.arrow_image} onClick={() => this.props.onClosePopup(false)} />} */}
                        <h3 className={this.props.cssfile.search_filter_header}>
                          {" "}
                          {this.props.isSrp ? "Modify search" : "Holidays"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.isSrp
                      ? this.props.cssfile.activity_search_wid_container_srp
                      : this.props.cssfile.activity_search_wid_container
                  }
                >
                  <div className="activity_search_wid_main">
                    <div className={this.props.cssfile.activity_way_title}>
                      {/* <h2>Activities</h2> */}
                    </div>
                    {/* {this.props?.sessionData?.type === 'B2B' &&

              <B2BWidgets {...this.state}
                styles={this.props.b2bStyles}
                type={'activity'}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                ratingHandler={this.ratingHandler}
                handleStarCheck={this.handleStarCheck}
                isOffline={this.props.isOffline}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                errorMessage={"Select Traveler"}
                isError={this.state.guestError}
              />} */}
                    <div
                      className={
                        this.props.cssfile.activity_search_details_container
                      }
                    >
                      <div
                        className={
                          this.props.cssfile.activity_search_details_main
                        }
                      >
                                      {this.props?.sessionData?.type == 'B2E'&&!this.props.isSrp&&  <TripTypeSelector
                                        bookingType={this.state.bookingType}
                                        selectedTripId={(value)=>{this.selectedTripId(value)}}
                                        styles={this.props.cssfile}
                                        bookingTypeId={this.state.bookingTripId}
                                        />}
                        <div
                          className={
                            this.props.cssfile.activity_search_details_row
                          }
                        >
                          <div
                            className={
                              this.props.cssfile.activity_search_column +
                              " " +
                              this.props.cssfile.activity_search_name
                            }
                          >
                            <div
                              id="search-widget-main"
                              className={
                                this.props.cssfile
                                  .activity_search_wid_inputfield
                              }
                              onClick={(e) => {
                                this.scrollToMyRef();
                                e.stopPropagation();
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    isAutoSuggestVisible:
                                      !prevState.isAutoSuggestVisible,
                                    isCalendarVisible: false,
                                    activityName: "",
                                    autoSuggestCityData: [],
                                    categoryList:
                                      this.state.categoryOriginalArray,
                                    monthData: this.state.monthOriginalArray,
                                    monthValue: "",
                                    isCategoryVisible: false,
                                    noresults: false,
                                    categoryValue: [],
                                  };
                                });
                              }}
                            >
                              <label>{"Destination"}</label>
                              <div
                                className={
                                  this.props.cssfile
                                    .activity_search_wid_inputfield_main
                                }

                                // onClick={this.scrollToMyRef}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  id="Layer_1"
                                  x="0px"
                                  y="0px"
                                  width="25px"
                                  height="25px"
                                  viewBox="0 0 25 25"
                                >
                                  <g>
                                    <path
                                      fill="#00425F"
                                      d="M12.5,6.659c-2.151,0-3.895,1.743-3.895,3.894c0,2.152,1.743,3.895,3.895,3.895 c2.15,0,3.894-1.743,3.894-3.895C16.394,8.402,14.65,6.659,12.5,6.659z M12.5,12.5c-1.075,0-1.947-0.872-1.947-1.948 c0-1.075,0.872-1.947,1.947-1.947c1.074,0,1.947,0.872,1.947,1.947C14.447,11.628,13.574,12.5,12.5,12.5z"
                                    ></path>
                                    <path
                                      fill="#00425F"
                                      d="M12.5,24.184c-1.663,0.008-3.228-0.791-4.196-2.143c-3.71-5.119-5.592-8.965-5.592-11.437 c0-5.406,4.383-9.788,9.789-9.787c5.405,0.001,9.787,4.382,9.787,9.787c0,2.472-1.881,6.318-5.591,11.437 C15.729,23.393,14.162,24.191,12.5,24.184z M12.5,2.941c-4.231,0.005-7.66,3.434-7.665,7.666c0,1.957,1.843,5.577,5.188,10.19 c0.993,1.369,2.907,1.672,4.276,0.678c0.259-0.188,0.488-0.418,0.678-0.678c3.345-4.613,5.188-8.233,5.188-10.19 C20.161,6.375,16.731,2.946,12.5,2.941z"
                                    ></path>
                                  </g>
                                </svg>
                                <input
                                  type="text"
                                  // readOnly
                                  // tabIndex={1}
                                  placeholder={`Enter Destination eg. Goa...`}
                                  autoComplete={"off"}
                                  id="myTextField"
                                  className={
                                    this.props.cssfile.search_widget_input_new
                                  }
                                  value={
                                    !cf.isValueEmpty(this.state.activityName) &&
                                    !this.state.isAutoSuggestVisible
                                      ? this.state.activityName
                                      : ""
                                  }
                                  onClick={(e) => {
                                    this.scrollToMyRef();
                                    e.stopPropagation();
                                    this.setState((prevState) => {
                                      return {
                                        ...prevState,
                                        isAutoSuggestVisible:
                                          !prevState.isAutoSuggestVisible,
                                        isCalendarVisible: false,
                                        activityName: "",
                                        autoSuggestCityData: [],
                                        categoryList:
                                          this.state.categoryOriginalArray,
                                        monthData:
                                          this.state.monthOriginalArray,
                                        monthValue: "",
                                        isCategoryVisible: false,
                                        noresults: false,
                                        categoryValue: [],
                                        selectedAutoSuggestData: { name: "" },
                                      };
                                    });
                                  }}
                                  // onChange={(event) => {
                                  //   event.stopPropagation();
                                  //   this.onChanegeSetActivityName(event.target.value);
                                  // }}

                                  // onFocus={() => {
                                  //   // e.stopPropagation();
                                  //   this.onInputFocus("autocomplete");
                                  // }}
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                    this.keyPress(e.key);
                                  }}
                                />

                                {this.state.isError && (
                                  <div className={this.props.cssfile.error}>
                                    {"Enter Destination"}
                                  </div>
                                )}

                                {this.state.isAutoSuggestVisible === true ? (
                                  <div
                                    className={
                                      this.props.cssfile
                                        .activity_autocomplete_value
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.cssfile
                                          .input_search_destination
                                      }
                                    >
                                      <svg
                                        width="50px"
                                        height="50px"
                                        viewBox="0,0,256,256"
                                      >
                                        <g
                                          fill="#979797"
                                          fillRule="nonzero"
                                          stroke="none"
                                          strokeWidth="1"
                                          strokeLinecap="butt"
                                          strokeLinejoin="miter"
                                          strokeMiterlimit="10"
                                          strokeDasharray=""
                                          strokeDashoffset="0"
                                          fontFamily="none"
                                          fontWeight="none"
                                          fontSize="none"
                                          textAnchor="none"
                                        >
                                          <g transform="scale(5.12,5.12)">
                                            <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                                          </g>
                                        </g>
                                      </svg>
                                      <input
                                        type="text"
                                        tabIndex={1}
                                        autoFocus
                                        autoComplete={"off"}
                                        id="myTextField"
                                        placeholder={`Enter destination eg. Goa...`}
                                        className={
                                          this.props.cssfile
                                            .search_widget_input_new
                                        }
                                        value={
                                          !cf.isValueEmpty(
                                            this.state.activityName
                                          )
                                            ? this.state.activityName
                                            : ""
                                        }
                                        onChange={(event) => {
                                          event.stopPropagation();
                                          this.onChanegeSetActivityName(
                                            event.target.value
                                          );
                                        }}
                                        onClick={(e) => {
                                          this.scrollToMyRef();
                                          e.stopPropagation();
                                          this.setState((prevState) => {
                                            return {
                                              ...prevState,
                                              isAutoSuggestVisible: true,
                                              isCalendarVisible: false,
                                              // activityName: "",
                                              // selectedAutoSuggestData: {name: ''}
                                            };
                                          });
                                        }}
                                        // onFocus={() => {
                                        //   // e.stopPropagation();
                                        //   this.onInputFocus("autocomplete");
                                        // }}
                                        onKeyDown={(e) => {
                                          e.stopPropagation();
                                          this.keyPress(e.key);
                                        }}
                                      />
                                    </div>
                                    {!cf.isValueEmpty(
                                      this.state.autoSuggestCountryData
                                    ) &&
                                      this.state.autoSuggestCountryData.length >
                                        0 &&
                                      this.state.countryData.map(
                                        (element, index) => {
                                          return (
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .mainactivitydrop
                                              }
                                            >
                                              <div
                                                id={"COUNTRY-" + index}
                                                key={element.search_text}
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_element
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    activityName: element.name,
                                                    isAutoSuggestVisible: false,
                                                  });
                                                  // this.onClickSetActivityName(element);
                                                }}
                                              >
                                                <img src="images/location-01.svg" />
                                                <div>
                                                  <div
                                                    className={
                                                      this.props.cssfile
                                                        .activity_autocomplete_name
                                                    }
                                                  >
                                                    {element.search_text}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    {!cf.isValueEmpty(
                                      this.state.autoSuggestCityData
                                    ) &&
                                      this.state.autoSuggestCityData.length >
                                        0 && (
                                        <div
                                          className={
                                            this.props.cssfile.dropdown_main
                                          }
                                        >
                                          {/* {this.state.autoSuggestCityData.length > 0 && (
                                    <p className={this.props.cssfile.suggestions}>
                                      SUGGESTIONS
                                    </p>
                                  )} */}

                                          {this.state.autoSuggestCityData
                                            .length > 0 &&
                                            this.state.autoSuggestCityData.map(
                                              (element, index) => {
                                                return (
                                                  <div
                                                    id={index}
                                                    name="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                                                    key={`city-${element.name}`}
                                                    className={
                                                      this.props.cssfile
                                                        .activity_autocomplete_element
                                                    }
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      this.setState({
                                                        activityName:
                                                          element.name,
                                                        isAutoSuggestVisible: false,
                                                        categoryList:
                                                          element.category,
                                                        monthData:
                                                          element.month,
                                                        selectedAutoSuggestData:
                                                          element,
                                                      });
                                                      // this.onClickSetActivityName(element);
                                                    }}
                                                  >
                                                    <img
                                                      src="/theme4/locationIcon.svg"
                                                      alt="location"
                                                    />
                                                    {/* <span></span> */}
                                                    <div>
                                                      <div
                                                        className={
                                                          this.props.cssfile
                                                            .activity_autocomplete_name
                                                        }
                                                      >
                                                        {element.name}
                                                      </div>
                                                      {/* <div className={this.props.cssfile.activity_autocomplete_newname}>
                                        23 packages
                                      </div> this will be picked later */}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      )}
                                    {this.state.noresults && (
                                      <div
                                        className={
                                          this.props.cssfile.dropdown_main
                                        }
                                      >
                                        {/* <p className={this.props.cssfile.suggestions}>
                                  SUGGESTIONS
                                </p> */}
                                        <div
                                          className={`${this.props.cssfile.mainactivitydrop} ${this.props.cssfile.noresult_main}`}
                                        >
                                          <div
                                            key={`noresults`}
                                            className={
                                              this.props.cssfile
                                                .activity_autocomplete_element
                                            }
                                          >
                                            {/* <img src="images/location-01.svg" /> */}
                                            {/* <span></span> */}
                                            <div>
                                              <div
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_name
                                                }
                                                onClick={() => {
                                                  this.setState({
                                                    activityName: "",
                                                  });
                                                }}
                                              >
                                                <div
                                                  className={
                                                    this.props.cssfile.no_res
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="19"
                                                    height="18"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M9.969 3.403V8.83c0 .124.05.242.138.33l3.863 3.836a.467.467 0 0 1-.33.796.475.475 0 0 1-.333-.137L9.445 9.818a1.393 1.393 0 0 1-.412-.988V3.403c0-.257.21-.465.468-.465.259 0 .468.208.468.465zm.747 14.33a8.652 8.652 0 0 1-4.864-.716.463.463 0 0 1-.2-.626.468.468 0 0 1 .63-.2 7.749 7.749 0 0 0 8.368-1.303 7.62 7.62 0 0 0 .525-10.816C12.314.941 7.439.707 4.287 3.55a7.622 7.622 0 0 0-.526 10.816v-1.009c0-.257.21-.465.469-.465.258 0 .468.208.468.465v2.092c0 .257-.21.466-.468.466H2.123a.467.467 0 0 1-.468-.466c0-.256.209-.465.468-.465h.953a8.579 8.579 0 0 1-2.15-4.567C.26 5.712 3.559 1.361 8.296.699c4.735-.66 9.112 2.616 9.779 7.319.665 4.701-2.628 9.048-7.359 9.715z"
                                                    ></path>
                                                  </svg>
                                                  <p>No Result Found</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div
                            className={this.props.cssfile.holiday_Dropcont}
                            ref={this.categoryRef}
                          >
                            <div
                              onClick={() => {
                                this.setState({ isCategoryVisible: true });
                              }}
                              className={this.props.cssfile.catg_lblCont}
                            >
                              <label>Category (Optional)</label>
                            </div>

                            <div className={this.props.cssfile.catg_lblInput}>
                              <span
                                className={this.props.cssfile.dropdownPosCat}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="11"
                                  onClick={() => {
                                    this.setState({ isCategoryVisible: true });
                                  }}
                                  className={
                                    this.state.isCategoryVisible
                                      ? this.props.cssfile.arrow_rotate
                                      : ""
                                  }
                                >
                                  <path
                                    fill-rule="evenodd"
                                    fill="#266AAC"
                                    d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                                  />
                                </svg>
                              </span>

                              <svg
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="25px"
                                height="25px"
                                viewBox="0 0 25 25"
                              >
                                <g>
                                  <path
                                    fill="#00425F"
                                    d="M18.847,1.618H6.152c-2.5,0-4.534,2.034-4.534,4.534v12.694c0,2.501,2.034,4.535,4.534,4.535h12.694 c2.501,0,4.535-2.034,4.535-4.535V6.152C23.382,3.652,21.348,1.618,18.847,1.618z M21.567,18.847c0,1.501-1.22,2.721-2.721,2.721 H6.152c-1.5,0-2.721-1.22-2.721-2.721V6.152c0-1.5,1.221-2.72,2.721-2.72h12.694c1.501,0,2.721,1.22,2.721,2.72V18.847z M10.232,5.245H6.605c-0.75,0-1.36,0.61-1.36,1.36v3.627c0,0.75,0.61,1.361,1.36,1.361h3.627c0.75,0,1.361-0.611,1.361-1.361V6.605 C11.594,5.855,10.982,5.245,10.232,5.245z M9.779,9.779H7.059V7.059h2.721V9.779z M18.395,5.245h-3.629 c-0.749,0-1.359,0.61-1.359,1.36v3.627c0,0.75,0.61,1.361,1.359,1.361h3.629c0.75,0,1.36-0.611,1.36-1.361V6.605 C19.755,5.855,19.145,5.245,18.395,5.245z M17.94,9.779h-2.72V7.059h2.72V9.779z M10.232,13.406H6.605 c-0.75,0-1.36,0.61-1.36,1.359v3.629c0,0.75,0.61,1.36,1.36,1.36h3.627c0.75,0,1.361-0.61,1.361-1.36v-3.629 C11.594,14.017,10.982,13.406,10.232,13.406z M9.779,17.94H7.059v-2.72h2.721V17.94z M18.395,13.406h-3.629 c-0.749,0-1.359,0.61-1.359,1.359v3.629c0,0.75,0.61,1.36,1.359,1.36h3.629c0.75,0,1.36-0.61,1.36-1.36v-3.629 C19.755,14.017,19.145,13.406,18.395,13.406z M17.94,17.94h-2.72v-2.72h2.72V17.94z"
                                  ></path>
                                </g>
                              </svg>
                              <input
                                type="text"
                                tabIndex={2}
                                placeholder="Select Categories"
                                value={this.state.categoryValue.map((ele) => {
                                  return ele.name ? ` ${ele.name}` : "";
                                })}
                                onClick={() => {
                                  this.setState({ isCategoryVisible: true });
                                }}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                  this.onInputFocus("categories");
                                }}
                              />
                              {this.state.isCategoryVisible && (
                                <div
                                  className={
                                    this.props.cssfile.dropDownlistmain
                                  }
                                >
                                  <ul
                                    className={
                                      this.props.cssfile
                                        .dropdown_value_container
                                    }
                                  >
                                    {this?.state?.categoryList?.map(
                                      (ele, id) => {
                                        return (
                                          <li key={"category" + id}>
                                            <label
                                              className={
                                                this.props.cssfile
                                                  .checkbox_label
                                              }
                                            >
                                              <span
                                                className={
                                                  this.props.cssfile.bagCheckbox
                                                }
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={this.categorySelected(
                                                    ele
                                                  )}
                                                  className={
                                                    this.props.cssfile.pointer
                                                  }
                                                  onClick={(e) =>
                                                    this.categoryHandler(e, ele)
                                                  }
                                                />
                                                <span
                                                  className={
                                                    this.props.cssfile.bagBox
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      this.props.cssfile
                                                        .bagCheck
                                                    }
                                                  ></span>
                                                </span>
                                              </span>
                                              <span
                                                className={
                                                  this.props.cssfile.advTitle
                                                }
                                              >
                                                {ele.name}
                                              </span>
                                            </label>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={`${this.props.cssfile.holiday_Dropcont} ${this.props.cssfile.holiday_Dropcont_last}`}
                          >
                            <div
                              onClick={() => {
                                this.setState({
                                  // isMonthVisible: true,
                                });
                              }}
                              className={this.props.cssfile.catg_lblCont}
                            >
                              <label
                                onClick={() => {
                                  this.setState({
                                    isMonthVisible: true,
                                  });
                                }}
                              >
                                Month of Travel (Optional)
                              </label>
                            </div>

                            <div
                              className={this.props.cssfile.catg_lblInput}
                              ref={this.monthRef}
                              onClick={() => {
                                this.setState({
                                  // isMonthVisible: true,
                                });
                              }}
                            >
                              <span
                                className={this.props.cssfile.dropdownPos}
                                onClick={() => {
                                  this.setState({
                                    isMonthVisible: true,
                                  });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="11"
                                  className={
                                    this.state.isMonthVisible
                                      ? this.props.cssfile.arrow_rotate
                                      : ""
                                  }
                                >
                                  <path
                                    fill-rule="evenodd"
                                    fill="#266AAC"
                                    d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                                  />
                                </svg>
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="25px"
                                height="25px"
                                viewBox="0 0 25 25"
                              >
                                <g>
                                  <path
                                    fill="#00425F"
                                    d="M19.025,3.177h-0.933V2.245c0-0.515-0.417-0.932-0.932-0.932c-0.516,0-0.933,0.417-0.933,0.932v0.932H8.771 V2.245c0-0.515-0.418-0.932-0.933-0.932S6.906,1.73,6.906,2.245v0.932H5.974C3.401,3.181,1.315,5.265,1.313,7.838v11.188 c0.003,2.574,2.089,4.658,4.661,4.662h13.052c2.573-0.004,4.658-2.088,4.661-4.662V7.838C23.684,5.265,21.599,3.181,19.025,3.177z M3.177,7.838c0-1.544,1.252-2.796,2.797-2.796h13.052c1.545,0,2.797,1.252,2.797,2.796V8.77H3.177V7.838z M19.025,21.822H5.974 c-1.545,0-2.797-1.252-2.797-2.797v-8.39h18.646v8.39C21.822,20.57,20.57,21.822,19.025,21.822z"
                                  ></path>
                                  <circle
                                    fill="#00425F"
                                    cx="12.499"
                                    cy="15.297"
                                    r="1.398"
                                  ></circle>
                                  <circle
                                    fill="#00425F"
                                    cx="7.838"
                                    cy="15.297"
                                    r="1.398"
                                  ></circle>
                                  <circle
                                    fill="#00425F"
                                    cx="17.161"
                                    cy="15.297"
                                    r="1.398"
                                  ></circle>
                                </g>
                              </svg>
                              <input
                                type="text"
                                tabIndex={3}
                                placeholder="Select Month"
                                value={this.state.monthValue}
                                onClick={() => {
                                  this.setState({
                                    monthValue: "",
                                    isMonthVisible: true,
                                  });
                                }}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                  this.onInputFocus("month");
                                }}
                                onKeyDown={(e) => {
                                  // e.stopPropagation();
                                  this.keyPressMonth(e.key);
                                }}
                              />
                              {this.state.isMonthVisible && (
                                <div
                                  className={
                                    this.props.cssfile.dropDownlistmainMonth
                                  }
                                >
                                  <ul
                                    className={
                                      this.props.cssfile
                                        .dropdown_value_container
                                    }
                                    id="MONTH-LIST"
                                  >
                                    {this.state.monthData.map((ele, id) => {
                                      return (
                                        <li key={"month" + id} id={id}>
                                          <label
                                            id={id}
                                            className={`${this.props.cssfile.checkbox_label} ${this.props.cssfile.option_month}`}
                                          >
                                            <span
                                              className={
                                                this.props.cssfile.advTitleDate
                                              }
                                              onClick={() => {
                                                this.setState({
                                                  monthValue: ele.month,
                                                  isMonthVisible: false,
                                                });
                                              }}
                                            >
                                              {ele.month}
                                            </span>
                                          </label>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                          <div
                            className={this.props.cssfile.activity_search_class}
                          >
                            <button
                              tabIndex={4}
                              className={
                                this.props.cssfile.activity_search_widget_btn
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                this.onClickSearchActivity();
                              }}
                              onFocus={(e) => {
                                e.stopPropagation();
                                this.onInputFocus("search");
                              }}
                            >
                              Search
                            </button>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={
                this.props.isSrp
                  ? this.props.cssfile.holidayMain_srp
                  : "container mx-auto xl:px-8"
              }
              ref={this.searchBoxRef}
              onClick={(e) => {
                e.stopPropagation();
                if (this.state.isAutoSuggestVisible) {
                  this.setState({
                    isAutoSuggestVisible: false,
                  });
                }
                // if (this.state.isCalendarVisible) {
                //   this.setState({
                //     isCalendarVisible: false,
                //   });
                // }
              }}
            >
              <div
                className={
                  this.props.isSrp
                    ? this.props.cssfile.activity_search_wid_container_srp
                    : this.props.cssfile.activity_search_wid_container
                }
              >
                <div className="activity_search_wid_main">
                  <div className={this.props.cssfile.activity_way_title}>
                    {/* <h2>Activities</h2> */}
                  </div>
                  {/* {this.props?.sessionData?.type === 'B2B' &&

              <B2BWidgets {...this.state}
                styles={this.props.b2bStyles}
                type={'activity'}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                ratingHandler={this.ratingHandler}
                handleStarCheck={this.handleStarCheck}
                isOffline={this.props.isOffline}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                errorMessage={"Select Traveler"}
                isError={this.state.guestError}
              />} */}
                  {/* <div className={this.props.cssfile.holiday_travSection}>
              </div> */}
                  <div
                    className={
                      this.props.cssfile.activity_search_details_container
                    }
                  >
                    <div
                      className={
                        this.props.cssfile.activity_search_details_main
                      }
                    >
                       {this.props?.sessionData?.type == 'B2E'&&!this.props.isSrp&&<TripTypeSelector
                                        bookingType={this.state.bookingType}
                                        selectedTripId={(value)=>{this.selectedTripId(value)}}
                                        styles={this.props.cssfile}
                                        bookingTypeId={this.state.bookingTripId}
                                      />}
                      <div
                        className={
                          this.props.cssfile.activity_search_details_row
                        }
                      >
                        <div
                          className={
                            this.props.cssfile.activity_search_column +
                            " " +
                            this.props.cssfile.activity_search_name
                          }
                        >
                          
                          <div
                            id="search-widget-main"
                            className={
                              this.props.cssfile.activity_search_wid_inputfield
                            }
                            onClick={(e) => {
                              this.scrollToMyRef();
                              e.stopPropagation();
                              this.setState((prevState) => {
                                return {
                                  ...prevState,
                                  isAutoSuggestVisible:
                                    !prevState.isAutoSuggestVisible,
                                  isCalendarVisible: false,
                                  activityName: "",
                                  autoSuggestCityData: [],
                                  categoryList:
                                    this.state.categoryOriginalArray,
                                  monthData: this.state.monthOriginalArray,
                                  monthValue: "",
                                  isCategoryVisible: false,
                                  noresults: false,
                                  categoryValue: [],
                                };
                              });
                            }}
                          >
                            <label>{"Destination"}</label>
                            <div
                              className={`${this.props.cssfile.activity_search_wid_inputfield_main} ${this.props.cssfile.holiday_wdgtNw}`}

                              // onClick={this.scrollToMyRef}
                            >
                              {/* <img type="image" src="/theme4/location-home.svg" alt="widget-icon" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="25px"
                                height="25px"
                                viewBox="0 0 25 25"
                              >
                                <g>
                                  <path
                                    fill="#00425F"
                                    d="M12.5,6.659c-2.151,0-3.895,1.743-3.895,3.894c0,2.152,1.743,3.895,3.895,3.895   c2.15,0,3.894-1.743,3.894-3.895C16.394,8.402,14.65,6.659,12.5,6.659z M12.5,12.5c-1.075,0-1.947-0.872-1.947-1.948   c0-1.075,0.872-1.947,1.947-1.947c1.074,0,1.947,0.872,1.947,1.947C14.447,11.628,13.574,12.5,12.5,12.5z"
                                  />
                                  <path
                                    fill="#00425F"
                                    d="M12.5,24.184c-1.663,0.008-3.228-0.791-4.196-2.143c-3.71-5.119-5.592-8.965-5.592-11.437   c0-5.406,4.383-9.788,9.789-9.787c5.405,0.001,9.787,4.382,9.787,9.787c0,2.472-1.881,6.318-5.591,11.437   C15.729,23.393,14.162,24.191,12.5,24.184z M12.5,2.941c-4.231,0.005-7.66,3.434-7.665,7.666c0,1.957,1.843,5.577,5.188,10.19   c0.993,1.369,2.907,1.672,4.276,0.678c0.259-0.188,0.488-0.418,0.678-0.678c3.345-4.613,5.188-8.233,5.188-10.19   C20.161,6.375,16.731,2.946,12.5,2.941z"
                                  />
                                </g>
                              </svg>
                              <input
                                type="text"
                                // readOnly
                                // tabIndex={1}
                                placeholder={`Enter Destination eg. Goa...`}
                                autoComplete={"off"}
                                id="myTextField"
                                className={
                                  this.props.cssfile.search_widget_input_new
                                }
                                value={
                                  !cf.isValueEmpty(this.state.activityName) &&
                                  !this.state.isAutoSuggestVisible
                                    ? this.state.activityName
                                    : ""
                                }
                                onClick={(e) => {
                                  this.scrollToMyRef();
                                  e.stopPropagation();
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      isAutoSuggestVisible:
                                        !prevState.isAutoSuggestVisible,
                                      isCalendarVisible: false,
                                      activityName: "",
                                      autoSuggestCityData: [],
                                      categoryList:
                                        this.state.categoryOriginalArray,
                                      monthData: this.state.monthOriginalArray,
                                      monthValue: "",
                                      isCategoryVisible: false,
                                      noresults: false,
                                      categoryValue: [],
                                      selectedAutoSuggestData: { name: "" },
                                    };
                                  });
                                }}
                                // onChange={(event) => {
                                //   event.stopPropagation();
                                //   this.onChanegeSetActivityName(event.target.value);
                                // }}

                                // onFocus={() => {
                                //   // e.stopPropagation();
                                //   this.onInputFocus("autocomplete");
                                // }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  this.keyPress(e.key);
                                }}
                              />

                              {this.state.isError && (
                                <div className={this.props.cssfile.error}>
                                  {"Enter Destination"}
                                </div>
                              )}

                              {this.state.isAutoSuggestVisible === true ? (
                                <div
                                  className={
                                    this.props.cssfile
                                      .activity_autocomplete_value
                                  }
                                >
                                  <div
                                    className={
                                      this.props.cssfile
                                        .input_search_destination
                                    }
                                  >
                                    <svg
                                      width="50px"
                                      height="50px"
                                      viewBox="0,0,256,256"
                                    >
                                      <g
                                        fill="#979797"
                                        fillRule="nonzero"
                                        stroke="none"
                                        strokeWidth="1"
                                        strokeLinecap="butt"
                                        strokeLinejoin="miter"
                                        strokeMiterlimit="10"
                                        strokeDasharray=""
                                        strokeDashoffset="0"
                                        fontFamily="none"
                                        fontWeight="none"
                                        fontSize="none"
                                        textAnchor="none"
                                      >
                                        <g transform="scale(5.12,5.12)">
                                          <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                                        </g>
                                      </g>
                                    </svg>
                                    <input
                                      type="text"
                                      tabIndex={1}
                                      autoFocus
                                      autoComplete={"off"}
                                      id="myTextField"
                                      placeholder={`Enter destination eg. Goa...`}
                                      className={
                                        this.props.cssfile
                                          .search_widget_input_new
                                      }
                                      value={
                                        !cf.isValueEmpty(
                                          this.state.activityName
                                        )
                                          ? this.state.activityName
                                          : ""
                                      }
                                      onChange={(event) => {
                                        event.stopPropagation();
                                        this.onChanegeSetActivityName(
                                          event.target.value
                                        );
                                      }}
                                      onClick={(e) => {
                                        this.scrollToMyRef();
                                        e.stopPropagation();
                                        this.setState((prevState) => {
                                          return {
                                            ...prevState,
                                            isAutoSuggestVisible: true,
                                            isCalendarVisible: false,
                                            // activityName: "",
                                            // selectedAutoSuggestData: {name: ''}
                                          };
                                        });
                                      }}
                                      // onFocus={() => {
                                      //   // e.stopPropagation();
                                      //   this.onInputFocus("autocomplete");
                                      // }}
                                      onKeyDown={(e) => {
                                        e.stopPropagation();
                                        this.keyPress(e.key);
                                      }}
                                    />
                                  </div>
                                  {!cf.isValueEmpty(
                                    this.state.autoSuggestCountryData
                                  ) &&
                                    this.state.autoSuggestCountryData.length >
                                      0 &&
                                    this.state.countryData.map(
                                      (element, index) => {
                                        return (
                                          <div
                                            className={
                                              this.props.cssfile
                                                .mainactivitydrop
                                            }
                                          >
                                            <div
                                              id={"COUNTRY-" + index}
                                              key={element.search_text}
                                              className={
                                                this.props.cssfile
                                                  .activity_autocomplete_element
                                              }
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({
                                                  activityName: element.name,
                                                  isAutoSuggestVisible: false,
                                                });
                                                // this.onClickSetActivityName(element);
                                              }}
                                            >
                                              <img src="images/location-01.svg" />
                                              <div>
                                                <div
                                                  className={
                                                    this.props.cssfile
                                                      .activity_autocomplete_name
                                                  }
                                                >
                                                  {element.search_text}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  {!cf.isValueEmpty(
                                    this.state.autoSuggestCityData &&
                                      this.state.autoSuggestCityData.length
                                  ) ? (
                                    <div
                                      className={
                                        this.state.autoSuggestCityData.length
                                          ? this.props.cssfile.dropdown_main
                                          : ""
                                      }
                                    >
                                      {/* {this.state.autoSuggestCityData.length > 0 && (
                                    <p className={this.props.cssfile.suggestions}>
                                      SUGGESTIONS
                                    </p>
                                  )} */}
                                      {this.state.autoSuggestCityData.length >
                                        0 &&
                                        this.state.autoSuggestCityData.map(
                                          (element, index) => {
                                            return (
                                              <div
                                                id={index}
                                                name="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                                                key={`city-${element.name}`}
                                                className={
                                                  this.props.cssfile
                                                    .activity_autocomplete_element
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    activityName: element.name,
                                                    isAutoSuggestVisible: false,
                                                    categoryList:
                                                      element.category,
                                                    monthData: element.month,
                                                    selectedAutoSuggestData:
                                                      element,
                                                  });
                                                  // this.onClickSetActivityName(element);
                                                }}
                                              >
                                                {/* <img src="images/location-01.svg" /> */}
                                                <img
                                                  src="/theme4/locationIcon.svg"
                                                  alt="location"
                                                />
                                                {/* <span></span> */}
                                                <div>
                                                  <div
                                                    className={
                                                      this.props.cssfile
                                                        .activity_autocomplete_name
                                                    }
                                                  >
                                                    {element.name}
                                                  </div>
                                                  {/* <div className={this.props.cssfile.activity_autocomplete_newname}>
                                        23 packages
                                      </div> this will be picked later */}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  ) : null}
                                  {this.state.noresults && (
                                    <div
                                      className={`${this.props.cssfile.dropdown_main} ${this.props.cssfile.noresult_main}`}
                                    >
                                      {/* <p className={this.props.cssfile.suggestions}>
                                  SUGGESTIONS
                                </p> */}
                                      <div
                                        className={
                                          this.props.cssfile.mainactivitydrop
                                        }
                                      >
                                        <div
                                          key={`noresults`}
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_element
                                          }
                                        >
                                          {/* <img src="images/location-01.svg" /> */}
                                          {/* <span></span> */}
                                          <div>
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .activity_autocomplete_name
                                              }
                                              onClick={() => {
                                                this.setState({
                                                  activityName: "",
                                                });
                                              }}
                                            >
                                              <div
                                                className={
                                                  this.props.cssfile.no_res
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="19"
                                                  height="18"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M9.969 3.403V8.83c0 .124.05.242.138.33l3.863 3.836a.467.467 0 0 1-.33.796.475.475 0 0 1-.333-.137L9.445 9.818a1.393 1.393 0 0 1-.412-.988V3.403c0-.257.21-.465.468-.465.259 0 .468.208.468.465zm.747 14.33a8.652 8.652 0 0 1-4.864-.716.463.463 0 0 1-.2-.626.468.468 0 0 1 .63-.2 7.749 7.749 0 0 0 8.368-1.303 7.62 7.62 0 0 0 .525-10.816C12.314.941 7.439.707 4.287 3.55a7.622 7.622 0 0 0-.526 10.816v-1.009c0-.257.21-.465.469-.465.258 0 .468.208.468.465v2.092c0 .257-.21.466-.468.466H2.123a.467.467 0 0 1-.468-.466c0-.256.209-.465.468-.465h.953a8.579 8.579 0 0 1-2.15-4.567C.26 5.712 3.559 1.361 8.296.699c4.735-.66 9.112 2.616 9.779 7.319.665 4.701-2.628 9.048-7.359 9.715z"
                                                  ></path>
                                                </svg>
                                                <p>No Result Found</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className={this.props.cssfile.holiday_Dropcont}>
                          <div
                            onClick={() => {
                              this.setState({ isCategoryVisible: true });
                            }}
                            className={this.props.cssfile.catg_lblCont}
                          >
                            <label>Category (Optional)</label>
                          </div>

                          <div
                            className={this.props.cssfile.catg_lblInput}
                            ref={this.categoryRef}
                          >
                            <span className={this.props.cssfile.dropdownPosCat}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="11"
                                onClick={() => {
                                  this.setState({ isCategoryVisible: true });
                                }}
                                className={
                                  this.state.isCategoryVisible
                                    ? this.props.cssfile.arrow_rotate
                                    : ""
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  fill="#266AAC"
                                  d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                                />
                              </svg>
                            </span>
                            <div className={this.props.cssfile.holiday_wdgtNw}>
                              {/* <img type="image" src="/theme4/category-home.svg" alt="widget-icon" /> */}
                              <svg
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="25px"
                                height="25px"
                                viewBox="0 0 25 25"
                              >
                                <g>
                                  <path
                                    fill="#00425F"
                                    d="M18.847,1.618H6.152c-2.5,0-4.534,2.034-4.534,4.534v12.694c0,2.501,2.034,4.535,4.534,4.535h12.694   c2.501,0,4.535-2.034,4.535-4.535V6.152C23.382,3.652,21.348,1.618,18.847,1.618z M21.567,18.847c0,1.501-1.22,2.721-2.721,2.721   H6.152c-1.5,0-2.721-1.22-2.721-2.721V6.152c0-1.5,1.221-2.72,2.721-2.72h12.694c1.501,0,2.721,1.22,2.721,2.72V18.847z    M10.232,5.245H6.605c-0.75,0-1.36,0.61-1.36,1.36v3.627c0,0.75,0.61,1.361,1.36,1.361h3.627c0.75,0,1.361-0.611,1.361-1.361V6.605   C11.594,5.855,10.982,5.245,10.232,5.245z M9.779,9.779H7.059V7.059h2.721V9.779z M18.395,5.245h-3.629   c-0.749,0-1.359,0.61-1.359,1.36v3.627c0,0.75,0.61,1.361,1.359,1.361h3.629c0.75,0,1.36-0.611,1.36-1.361V6.605   C19.755,5.855,19.145,5.245,18.395,5.245z M17.94,9.779h-2.72V7.059h2.72V9.779z M10.232,13.406H6.605   c-0.75,0-1.36,0.61-1.36,1.359v3.629c0,0.75,0.61,1.36,1.36,1.36h3.627c0.75,0,1.361-0.61,1.361-1.36v-3.629   C11.594,14.017,10.982,13.406,10.232,13.406z M9.779,17.94H7.059v-2.72h2.721V17.94z M18.395,13.406h-3.629   c-0.749,0-1.359,0.61-1.359,1.359v3.629c0,0.75,0.61,1.36,1.359,1.36h3.629c0.75,0,1.36-0.61,1.36-1.36v-3.629   C19.755,14.017,19.145,13.406,18.395,13.406z M17.94,17.94h-2.72v-2.72h2.72V17.94z"
                                  />
                                </g>
                              </svg>
                              <input
                                type="text"
                                tabIndex={2}
                                placeholder="Select Categories"
                                value={this.state.categoryValue.map((ele) => {
                                  return ele.name ? ` ${ele.name}` : "";
                                })}
                                onClick={() => {
                                  this.setState({ isCategoryVisible: true });
                                }}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                  this.onInputFocus("categories");
                                }}
                              />
                            </div>
                            {this.state.isCategoryVisible && (
                              <div
                                className={this.props.cssfile.dropDownlistmain}
                              >
                                <ul
                                  className={
                                    this.props.cssfile.dropdown_value_container
                                  }
                                >
                                  {this?.state?.categoryList?.map((ele, id) => {
                                    return (
                                      <li key={"category" + id}>
                                        <label
                                          className={
                                            this.props.cssfile.checkbox_label
                                          }
                                        >
                                          <span
                                            className={
                                              this.props.cssfile.bagCheckbox
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              checked={this.categorySelected(
                                                ele
                                              )}
                                              className={
                                                this.props.cssfile.pointer
                                              }
                                              onClick={(e) =>
                                                this.categoryHandler(e, ele)
                                              }
                                            />
                                            <span
                                              className={
                                                this.props.cssfile.bagBox
                                              }
                                            >
                                              <span
                                                className={
                                                  this.props.cssfile.bagCheck
                                                }
                                              ></span>
                                            </span>
                                          </span>
                                          <span
                                            className={
                                              this.props.cssfile.advTitle
                                            }
                                          >
                                            {ele.name}
                                          </span>
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={`${this.props.cssfile.holiday_Dropcont} ${this.props.cssfile.holiday_Dropcont_last}`}
                        >
                          <div
                            onClick={() => {
                              this.setState({
                                // isMonthVisible: true,
                              });
                            }}
                            className={this.props.cssfile.catg_lblCont}
                          >
                            <label
                              onClick={() => {
                                this.setState({
                                  isMonthVisible: true,
                                });
                              }}
                            >
                              Month of Travel (Optional)
                            </label>
                          </div>

                          <div
                            className={this.props.cssfile.catg_lblInput}
                            ref={this.monthRef}
                            onClick={() => {
                              this.setState({
                                // isMonthVisible: true,
                              });
                            }}
                          >
                            <span
                              className={this.props.cssfile.dropdownPos}
                              onClick={() => {
                                this.setState({
                                  isMonthVisible: true,
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="11"
                                className={
                                  this.state.isMonthVisible
                                    ? this.props.cssfile.arrow_rotate
                                    : ""
                                }
                              >
                                <path
                                  fill-rule="evenodd"
                                  fill="#266AAC"
                                  d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                                />
                              </svg>
                            </span>
                            <div className={this.props.cssfile.holiday_wdgtNw}>
                              {/* <img type="image" src="/theme4/Calendar-home.svg" alt="widget-icon" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="25px"
                                height="25px"
                                viewBox="0 0 25 25"
                              >
                                <g>
                                  <path
                                    fill="#00425F"
                                    d="M19.025,3.177h-0.933V2.245c0-0.515-0.417-0.932-0.932-0.932c-0.516,0-0.933,0.417-0.933,0.932v0.932H8.771   V2.245c0-0.515-0.418-0.932-0.933-0.932S6.906,1.73,6.906,2.245v0.932H5.974C3.401,3.181,1.315,5.265,1.313,7.838v11.188   c0.003,2.574,2.089,4.658,4.661,4.662h13.052c2.573-0.004,4.658-2.088,4.661-4.662V7.838C23.684,5.265,21.599,3.181,19.025,3.177z    M3.177,7.838c0-1.544,1.252-2.796,2.797-2.796h13.052c1.545,0,2.797,1.252,2.797,2.796V8.77H3.177V7.838z M19.025,21.822H5.974   c-1.545,0-2.797-1.252-2.797-2.797v-8.39h18.646v8.39C21.822,20.57,20.57,21.822,19.025,21.822z"
                                  />
                                  <circle
                                    fill="#00425F"
                                    cx="12.499"
                                    cy="15.297"
                                    r="1.398"
                                  />
                                  <circle
                                    fill="#00425F"
                                    cx="7.838"
                                    cy="15.297"
                                    r="1.398"
                                  />
                                  <circle
                                    fill="#00425F"
                                    cx="17.161"
                                    cy="15.297"
                                    r="1.398"
                                  />
                                </g>
                              </svg>
                              <input
                                type="text"
                                tabIndex={3}
                                placeholder="Select Month"
                                value={this.state.monthValue}
                                onClick={() => {
                                  this.setState({
                                    monthValue: "",
                                    isMonthVisible: true,
                                  });
                                }}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                  this.onInputFocus("month");
                                }}
                                onKeyDown={(e) => {
                                  // e.stopPropagation();
                                  this.keyPressMonth(e.key);
                                }}
                              />
                            </div>
                            {this.state.isMonthVisible && (
                              <div
                                className={
                                  this.props.cssfile.dropDownlistmainMonth
                                }
                              >
                                <ul
                                  className={
                                    this.props.cssfile.dropdown_value_container
                                  }
                                  id="MONTH-LIST"
                                >
                                  {this.state.monthData.map((ele, id) => {
                                    return (
                                      <li key={"month" + id} id={id}>
                                        <label
                                          id={id}
                                          className={`${this.props.cssfile.checkbox_label} ${this.props.cssfile.option_month}`}
                                        >
                                          <span
                                            className={
                                              this.props.cssfile.advTitleDate
                                            }
                                            onClick={() => {
                                              this.setState({
                                                monthValue: ele.month,
                                                isMonthVisible: false,
                                              });
                                            }}
                                          >
                                            {ele.month}
                                          </span>
                                        </label>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                        <div
                          className={this.props.cssfile.activity_search_class}
                        >
                          <button
                            tabIndex={4}
                            className={
                              this.props.cssfile.activity_search_widget_btn
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onClickSearchActivity();
                            }}
                            onFocus={(e) => {
                              e.stopPropagation();
                              this.onInputFocus("search");
                            }}
                          >
                            Search
                          </button>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
